/*! Pushy - v1.0.0 - 2016-3-1
* Pushy is a responsive off-canvas navigation menu using CSS transforms & transitions.
* https://github.com/christophery/pushy/
* by Christopher Yee */

$menu_width: 320px !default;

/* Menu Appearance */

.pushy {
    position: fixed;
    width: $menu_width;
    height: 100%;
    top: 0;
    z-index: 9999;
    background: $black;
	border-right: $border-size solid #FFF;
    overflow: auto;
    visibility: hidden;
    -webkit-overflow-scrolling: touch; /* enables momentum scrolling in iOS overflow elements */

	.primary {
		button, a {
	        display: block;
	        color: #FFFFFF;
			font-size: 20px;
			font-size: 1.25rem;
	        padding: 10px 30px;
	        text-decoration: none;
	        outline: 0;
	    }

	    a:hover {
	        background-color: $red;
	    }
	}

	.sub {
		margin: 20px 0;
		font-size: 0;
		padding: 0 20px;

		li {
			display: inline-block;
		}
		a {
	        color: #c5c5c5;
			font-size: 16px;
			font-size: 1rem;
			text-decoration: none;
			padding: 10px;
	    }

	    a:hover {
	        color: #FFF;
	    }
	}

	.social {
	  font-size: 0;
	  text-align: left;
	  margin: 30px 0 20px 0;
	  padding: 0 30px;
	}

	.social li {
	  display: inline-block;
	  margin: 0 1rem 1rem 0;
	}

	.social li a {
	    display: block;
	    padding: 0;
	}

	.social li a svg {
	    fill: #ffffff;
	    display: block;
	    width: 20px;
	    height: 20px;
	}

	.social li a:hover svg {
	    fill: $red;
	}

	/* Search */
	.search-field {
	    position: relative;
		margin: 0 0 20px 0;
	}

	.search-field input[type="search"] {
		box-sizing: border-box;
	    background: #FFFFFF;
	    color: $black;
	    border: none;
	    font-size: 1rem;
	    line-height: 2.85rem;
	    padding: 5px 90px 5px 30px;
	    width: 100%;
		height: 44px;

	    &:focus {
	      outline: none;
	      border-color: $red;
	      box-shadow: 0px 0px 3px $red;
	    }

	    &::-webkit-input-placeholder { color: $black; }
	    &:-moz-placeholder { color: $black; }
	    &::-moz-placeholder { color: $black; }
	    &:-ms-input-placeholder { color: $black; }
	}

	.search-field button {
	    position: absolute;
	    top: 50%;
	    right: 30px;
	    background: none;
	    border: none;
	    padding: 0;
	    margin: 0;
	    margin-top: -10px;
	    width: 20px;
	    height: 20px;

	    svg {
	      display: block;
	      width: 20px;
	      height: 20px;
	      fill:  $black;
	    }
	}

    ul:first-child {
        margin-top: 10px;
    }

    &.pushy-left {
        left: 0;
    }

    &.pushy-right {
        right: 0;
    }
}

/* Menu Movement */

.pushy-left {
    -webkit-transform: translate3d( unquote('-' + $menu_width) ,0,0);
    -ms-transform: translate3d( unquote('-' + $menu_width) ,0,0);
    transform: translate3d( unquote('-' + $menu_width) ,0,0);
}

.pushy-open-left {
    #container,
    .push {
        -webkit-transform: translate3d( $menu_width ,0,0);
        -ms-transform: translate3d( $menu_width ,0,0);
        transform: translate3d( $menu_width ,0,0);
    }
}

.pushy-right {
    -webkit-transform: translate3d( $menu_width ,0,0);
    -ms-transform: translate3d( $menu_width ,0,0);
    transform: translate3d( $menu_width ,0,0);
}

.pushy-open-right {
    #container,
    .push {
        -webkit-transform: translate3d( unquote('-' + $menu_width) ,0,0);
        -ms-transform: translate3d( unquote('-' + $menu_width) ,0,0);
        transform: translate3d( unquote('-' + $menu_width) ,0,0);
    }
}

.pushy-open-left,
.pushy-open-right {
    .pushy {
        -webkit-transform: translate3d(0,0,0);
        -ms-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }
}

/* Menu Transitions */

#container,
.pushy,
.push {
    transition: transform .2s cubic-bezier(.16, .68, .43, .99);
}

/* Site Overlay */

.site-overlay {
    display: none;
}

.pushy-open-left,
.pushy-open-right {
    .site-overlay {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9998;
        background-color: rgba(0,0,0,0.5);
        -webkit-animation: fade 500ms;
        animation: fade 500ms;
    }
}

@keyframes fade {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes fade {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

/* Submenu Appearance */

.pushy-submenu {
    ul {
        padding-left: 15px;
        transition: max-height 0.2s ease-in-out;

        .pushy-link {
            transition: opacity 0.2s ease-in-out;
        }
    }

    /* Submenu Icon */

    > a {
        position: relative;
    }

    > a::after {
        content: '';
        display: block;
        height: 11px;
        width: 8px;
        position: absolute;
        top: 50%;
        right: 15px;
        background: url("../img/arrow.svg") no-repeat;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        transition: transform 0.2s;
    }
}

/* Submenu Movement */

.pushy-submenu-closed {
    ul {
        max-height: 0;
        overflow: hidden;
    }

    .pushy-link {
        opacity: 0;
    }
}

.pushy-submenu-open {
  background: $dark-gray;
    ul {
        max-height: 1000px;
    }

    .pushy-link {
        opacity: 1;
    }

    /* Submenu Icon */

    a::after {
        -webkit-transform: translateY(-50%) rotate(90deg);
        -ms-transform: translateY(-50%) rotate(90deg);
        transform: translateY(-50%) rotate(90deg);
    }
}

.no-csstransforms3d {
    .pushy-submenu-closed {
        ul {
            max-height: none;
            display: none;
        }
    }
}
