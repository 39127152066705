.home-banner {
	position: relative;
	background-color: $black;
	height: 453px;

	.Wallop {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	&.loading {
		.loader {
			display: block;
			opacity: 1;
			visibility: visible;
		}
	}

	&-wrapper {
		.home-banner {
			height: auto;
			video {
				width: 100%;
				display: block;
			}
		}
	}
}

@media screen and (max-width: 64em) {
	.home-banner {
		padding-top: 0;
		height: auto;
	}
	.home-banner .Wallop {
		position: relative;
		top: auto;
		left: auto;
	}
}

.home-slide {
	height: 453px;
	background-position: center;
	background-size: cover;
}

.home-banner .home-slide .caption {
	opacity: 0;
	visibility: 0;
	bottom: auto;
	top: 100%;
}

/* Overlay */
.home-banner .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	color: #FFF;
	text-align: center;
	z-index: 2;
	padding: 120px 0 40px 0;

	@supports(display:flex) {
		display: flex;
		align-items: center;
	}
}

.home-banner .overlay .overlay-content {
	@supports (display: flex) {
		animation: fade-in 1 0.6s ease-in-out;
	}
}

@keyframes fade-in {
	0% {
		transform: translateY(-30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

/* Banner Caption */
.home-banner .caption {
	display: block;
	text-decoration: none;
	position: absolute;
	bottom: 50px;
	right: 50px;
	color: #FFF;
	line-height: 25px;
	white-space: nowrap;

	text-align: right;
	z-index: 3;

	transition: 0.5s all ease;

	&:hover {
		opacity: 0.5;
	}
}

.home-banner .caption .address {
	font-weight: bold;
	font-size: 16px;
	font-size: 1rem;
}

.home-banner .overlay .overlay-content {
	width: 100%;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);

	@supports (display: flex) {
		position: static;
		transform: none;
	}
}

.home-banner .logo {
	display: block;
	width: 112px;
	border: $border-size solid #FFF;
	margin: 0 auto 15px auto;
}

.home-banner h1 {
	color: #FFF;
	font-weight: bold;
	font-size: 40px;
  font-size: 2.5rem;
	font-family: $font-title;
	line-height: 1.2;
	margin: 0 0 20px 0;
}

.home-banner p {
	color: #FFF;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.2;
	margin: 12px 0 20px 0;
}

.home-banner .loader {
	opacity: 0;
	visibility: hidden;

	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -50px;
	margin-left: -50px;
	z-index: 2;
}
.home-banner a.button{
	color: white;
	text-decoration: none;
	&:hover{
		background-color: #cc2026;
	}
}

.home-banner {
	.Wallop-buttonPrevious,
	.Wallop-buttonNext {
	    position: absolute;
	    bottom: 50px;
	    display: block;
	    cursor: pointer;
	    width: 50px;
	    height: 50px;
	    background-color: rgba(0,0,0,0.5);
	    transition: 0.5s all ease;
		z-index: 3;
		border: $border-size solid #FFF;
	}
	.Wallop-buttonPrevious:hover,
	.Wallop-buttonNext:hover {
		background-color: $red;
	}
	.Wallop-buttonPrevious {
	    left: 50px;
	}

	.Wallop-buttonNext {
	    left: 98px;
	}

	.Wallop-buttonPrevious svg,
	.Wallop-buttonNext svg {
	    width: 25px;
		height: 46px;
		fill: #FFFFFF;
		display: block;
		margin: 0 auto;
	}

	.Wallop-buttonPrevious:hover svg,
	.Wallop-buttonNext:hover svg {
		// fill: $red;
	}
}

/* Form */
.home-banner form {
	@supports (display: flex) {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 -1%;
	}
}

.home-banner form label {
	display: block;
	font-size: 16px;
	font-size: 1rem;
	font-weight: bold;
	margin: 0 0 4px 0;
	margin: 0 0 0.25rem 0;
}

.home-banner form .field {
	margin: 10px 1%;
	float: left;
	width: 23%;

	@supports (display: flex) {
		flex-basis: 23%;
		margin: 10px 1%;
		width: auto;
	}
}

.home-banner form .field.larger {
	margin: 10px 1%;
	float: left;
	width: 48%;

	@supports (display: flex) {
		flex-basis: 48%;
		margin: 10px 1%;
		width: auto;
	}
}

.home-banner form .extra-fields {
	text-align: left;
	transition: 0.5s all ease;
	opacity: 0;
	visibility: hidden;
	height: 0;

	@supports (display: flex) {
		display: flex;
		flex-basis: 100%;
		// flex-wrap: wrap;
	}
}

.home-banner form .extra-fields.show {
	opacity: 1;
	visibility: visible;
	height: auto;
}

.home-banner form .field.showmore {
	width: 98%;

	@supports (display: flex) {
		width: auto;
	}
}

.home-banner form input,
.home-banner form select,
.home-banner form button[type="submit"] {
	display: block;
	width: 100%;
	line-height: 20px;
	font-size: 16px;
	font-size: 1rem;
	padding: 15px 15px;
	background: #FFF;
	border: $border-size solid #FFF;
	height: 54px;
	box-shadow: 0px 0px 20px rgba(255,255,255,0.5);
	color: #000;
}

.home-banner form input[type="text"]:focus,
.home-banner form select:focus {
	border-color: #55d0ff;
    outline: none;
    -webkit-box-shadow: 0px 0px 20px rgba(85, 208, 255, 0.35);
    box-shadow: 0px 0px 20px rgba(85, 208, 255, 0.35);
}

.home-banner form button[type="submit"] {
	background-color: $red;
	color: #FFF;
	font-weight: bold;
	&:hover {
		background-color: $black;
	}
}

.home-banner form button.moreoptions {
	font-weight: bold;
	position: relative;
	padding: 10px 36px 10px 10px;
	line-height: 20px;
	line-height: 1.25rem;
}

.home-banner form button.moreoptions:focus {
	outline: none;
	box-shadow: 0px 0px 10px rgba(255,255,255,0.5);
	transition: 0.5s all ease;
}

.home-banner form button.moreoptions .less {
	display: none;
}

.home-banner form button.moreoptions.toggled .less {
	display: inline;
}

.home-banner form button.moreoptions.toggled .more {
	display: none;
}

.home-banner form button.moreoptions.toggled svg {
	transform: rotate(180deg);
}

.home-banner form button.moreoptions svg {
	display: block;
	width: 20px;
	height: 20px;
	fill: #FFF;
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -10px;
}

.home-banner form button.moreoptions:hover svg {
	animation: flow 1 0.5s ease;
}

.home-banner form button.moreoptions.toggled:hover svg {
	animation: none;
}
@keyframes flow {
	0% {
		transform: translateY(0px);
		opacity: 1;
	}
	50% {
		transform: translateY(20px);
		opacity: 0;
	}
	51% {
		transform: translateY(-20px);
		opacity: 0;
	}
	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

@media screen and (max-width: 64em) {
	.home-banner .overlay {
		//padding: 140px 40px;
		//position: relative;
		//top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		z-index: 2;
	}
	.home-banner .overlay .overlay-content {
		position: static;
		transform: none;
	}
	.home-banner .Wallop,
	.home-banner .Wallop-list {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.home-banner .Wallop-item {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.home-slide {
		padding: 0;
		height: 300px;
	}
	.home-banner-custom{
		.overlay{
			position: absolute;
			top: 0;
			padding: 0 20px !important;
			h1{
				margin-bottom: 0;
			}
		}
	}	
}

@media screen and (max-width: 74em) {
	.home-banner .logo {
		display: none;
	}
}

@media screen and (max-width: 42em) {
	.home-banner form .field,
	.home-banner form .field.larger {
		flex-basis: 98%;
	}
	.home-banner form .field.search-button {
		order: 3;
	}
	.home-banner .overlay {
		padding: 100px 40px 140px 40px;
	}
	.home-banner h1 {
		font-size: 40px;
		font-size: 2.5rem;
		line-height: 1;
	}
	.home-banner form .extra-fields {
		@supports (display: flex) {
			flex-wrap: wrap;
		}
	}
	.home-banner form button.moreoptions {
		width: 100%;
		background-color: rgba(255,255,255,0.1);
		line-height: 20px;
    font-size: 16px;
    font-size: 1rem;
    padding: 15px;
		text-align: left;
	}
	.home-banner form button.moreoptions svg {
		right: 15px;
	}
}

@media screen and (max-width: 36em) {
	.home-banner .overlay {
    padding: 50px 40px 120px 40px;
	}
}
