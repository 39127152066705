.section {
  padding-top: rem-calc(100);
  padding-bottom: rem-calc(100);
  @media (max-width: 42em) {
    padding-top: rem-calc(45);
    padding-bottom: rem-calc(45);
  }
}

.pt-0,
.no-padding-top {
  padding-top: 0 !important;
}

.pb-0,
.no-padding-bottom {
  padding-bottom: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.text {
  &-left {
    text-align: left !important;
  }
  &-right {
    text-align: right !important;
  }
  &-center {
    text-align: center !important;
  }
}

.h-auto {
  height: auto !important;
}

.mb-4 {
  margin-bottom: rem-calc(40);
}

.mt-30 {
  margin-top: 30px;
}

button[type="submit"],
input[type="submit"],
a.down-button {
  padding: 15px 15px 13px !important;
  font-size: 1rem;
  text-transform: none;
  font-family: $font-title;
  border-radius: 0px;
  border: solid 2px #fff;
}

.newsletter_subscription input[type="submit"] {
  padding: 12px 15px 8px !important;
}

.description .desc-content.show {
  max-height: 1600px;
}

.listing .image .leased:after,
.listing .image .sold:after {
  display: none;
}

.listing .image .label-status {
  background-color: #000;
  padding: 6px 10px;
  color: #fff;
  display: inline-block;
  text-transform: capitalize;
  font-family: $font-title;
  font-size: 1rem;
}

.listing .suburb,
.listing .image .sold {
  position: relative;
  width: auto;
  font-family: $font-title;
}

.group-label {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
}

.group-label .current .label-status {
  display: none;
}

.gallery .slide .label-status {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #000;
  color: #fff;
  display: none;
  padding: 6px 10px;
  font-weight: bold;
  text-transform: capitalize;
}

.gallery .slide .current {
  display: none;
}

.homebit .read {
  padding: 15px 15px 13px;
}

.home-blog .title a {
  margin: 0 0 20px 12px;
}

.custom-form label {
  font-size: 1rem;
  line-height: 1rem;
  &.error {
    padding: 0.5rem 0.75rem;
    margin: 0.75rem 0rem;
    margin-bottom: 0;
    background: #fae4e8;
    color: rgb(126, 7, 20);
    border: solid 1px #d29ca6;
    border-radius: 5px;
    width: 100%;
    line-height: 1.5;
    font-weight: 400;
  }
}

.custom-form input[type="radio"],
.custom-form input[type="checkbox"] {
  margin: 1px 10px 2px 0;
  & ~ label {
    font-weight: 400;
  }
}

.office-links a,
.more-button,
.agent-button,
.go-button,
.sec-title a,
.button,
.read {
  padding: 15px 15px 13px;
  font-size: 1rem;
  text-transform: none;
  font-family: $font-title;
  border-radius: 0px;
  border: solid 2px #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  line-height: 1;
}

.custom-carousel {
  overflow: hidden;
  .slick {
    &-slide {
      opacity: 1;
    }
    &-next,
    &-prev {
      position: absolute;
      z-index: 1;
      color: #fff;
      top: 50%;
      transform: translateY(-50%);
      transition: color 0.2s ease-out;
      font-size: 2.7rem;
      cursor: pointer;
      &:hover {
        color: $red;
      }
    }
    &-next {
      right: 2rem;
    }
    &-prev {
      left: 2rem;
    }
    &-disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    @media screen and (max-width: 42em) {
      &-next,
      &-prev {
        font-size: 2rem;
      }
      &-next {
        right: 0.5rem;
      }
      &-prev {
        left: 0.5rem;
      }
    }
  }
}

.details p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.list-carousel {
  .slick {
    &-slide {
      opacity: 1;
    }
    &-next,
    &-prev {
      position: absolute;
      z-index: 1;
      color: $red;
      top: 50%;
      transform: translateY(-50%);
      transition: color 0.2s ease-out;
      font-size: 2.7rem;
      cursor: pointer;
      &:hover {
        color: $red;
      }
    }
    &-next {
      right: 0;
    }
    &-prev {
      left: 0;
    }
    &-disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    &-dots {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 1.5rem;
      gap: 0.4rem;
      li {
        button {
          display: block;
          color: transparent;
          width: 10px;
          height: 10px;
          background: #ced2d5;
          border-radius: 50%;
          overflow: hidden;
          transition: background 0.2s ease-out;
        }
        &.slick-active {
          button {
            background-color: $red;
          }
        }
      }
    }
    @media screen and (max-width: 42em) {
      &-next,
      &-prev {
        font-size: 2rem;
      }
      &-next {
        right: 0.5rem;
      }
      &-prev {
        left: 0.5rem;
      }
      &-dots {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
        gap: 0.4rem;
        li {
          button {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }
  .listing {
    margin: 0;
  }
  &.flex-list-carousel {
    .slick {
      &-track {
        display: flex !important;
        margin: 0 auto;
      }
      &-slide {
        height: inherit !important;
        .custom-agent {
          height: 100%;
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.form-wrapper {
  .userform {
    margin-top: rem-calc(15);
    .userformsstep {
      display: flex;
      flex-wrap: wrap;
      gap: rem-calc(15);
      & > .field {
        flex: 1 1 100%;
        box-sizing: border-box;
        &.w-half {
          @media (min-width: 800px) {
            flex: 1 1 calc(50% - 15px);
          }
        }
        &.checkboxset {
          & > .middleColumn {
            display: flex;
            flex-direction: column;
            & > legend {
              order: -1;
            }
          }
        }
        label {
          display: inline-block;
          font-weight: 400;
        }
        .middleColumn {
          width: 100%;
        }
        .form-control,
        input.js-autocomplete-address,
        .select-dropdown {
          width: 100%;
          min-height: 45px;
          border-radius: 0;
          margin: 0;
          padding: 15px;
          border: 1px solid #ced2d5;
          outline: none;
        }
        textarea {
          min-height: 150px;
        }
      }
    }
    .mt-2rem {
      margin-top: rem-calc(30);
      display: flex;
      @media (max-width: 42em) {
        margin-top: rem-calc(20);
      }
      .button {
        background-color: $red;
        color: #fff;
        min-width: 120px;
        &:hover {
          background-color: $black;
        }
      }
    }
  }
}

.responsive-embed {
  position: relative;
  height: 0;
  margin-bottom: 0;
  padding-bottom: 75%;
  overflow: hidden;
  &.widescreen {
    padding-bottom: 56.25%;
  }
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.subtitle {
  font-family: $font-title;
  font-size: rem-calc(16);
  color: $red;
  margin-bottom: rem-calc(5);
}

.button-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem-calc(10);
  .button {
    background-color: $red;
    color: #fff;
    text-decoration: none;
    &:hover {
      background-color: $black;
    }
  }
}

.text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > *:last-child {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: rem-calc(10);
    font-size: rem-calc(19);
    font-weight: bold;
  }
  p {
    margin-bottom: rem-calc(20);
  }
  &-update {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font-title;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
      margin-bottom: rem-calc(10);
      line-height: 1.2;
    }
    h2 {
      font-size: rem-calc(22);
    }
    h4 {
      font-size: rem-calc(17);
    }
    h5 {
      font-size: rem-calc(16);
    }
    h6 {
      font-size: rem-calc(15);
    }
    p {
      line-height: 1.4;
    }
  }
  &.text-center {
    align-items: center;
    .button-group {
      justify-content: center;
    }
  }
  &.text-right {
    align-items: flex-end;
    .button-group {
      justify-content: flex-end;
    }
  }
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
  row-gap: rem-calc(30);
  .columns,
  .column {
    float: none;
  }
}

.flex-list-carousel {
  .columns,
  .column {
    float: none;
  }
}

.image-black-and-white {
  img {
    filter: grayscale(100%);
  }
}

.float-none {
  float: none !important;
}

.image-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  & > img {
    width: 100%;
    height: auto;
  }
  &.modal {
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 70px;
      height: 70px;
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20fill%3D'%23ffffff'%20height%3D'200px'%20width%3D'200px'%20version%3D'1.1'%20id%3D'Capa_1'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%20viewBox%3D'0%200%2060%2060'%20xml%3Aspace%3D'preserve'%3E%3Cg%20id%3D'SVGRepo_bgCarrier'%20stroke-width%3D'0'%3E%3C%2Fg%3E%3Cg%20id%3D'SVGRepo_tracerCarrier'%20stroke-linecap%3D'round'%20stroke-linejoin%3D'round'%3E%3C%2Fg%3E%3Cg%20id%3D'SVGRepo_iconCarrier'%3E%3Cg%3E%3Cpath%20d%3D'M45.563%2C29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205%2C14.289%2C22%2C14.629%2C22%2C15v30%20c0%2C0.371%2C0.205%2C0.711%2C0.533%2C0.884C22.679%2C45.962%2C22.84%2C46%2C23%2C46c0.197%2C0%2C0.394-0.059%2C0.563-0.174l22-15%20C45.836%2C30.64%2C46%2C30.331%2C46%2C30S45.836%2C29.36%2C45.563%2C29.174z%20M24%2C43.107V16.893L43.225%2C30L24%2C43.107z'%3E%3C%2Fpath%3E%3Cpath%20d%3D'M30%2C0C13.458%2C0%2C0%2C13.458%2C0%2C30s13.458%2C30%2C30%2C30s30-13.458%2C30-30S46.542%2C0%2C30%2C0z%20M30%2C58C14.561%2C58%2C2%2C45.439%2C2%2C30%20S14.561%2C2%2C30%2C2s28%2C12.561%2C28%2C28S45.439%2C58%2C30%2C58z'%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

.custom-reveal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease-out;
  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }
  .overlay {
    background: #000;
    opacity: 0.7;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .inner {
    position: relative;
    z-index: 2;
    width: 95%;
    max-width: 1050px;
    height: auto;
    max-height: 90%;
  }
  .reveal-close-button {
    position: absolute;
    font-size: 60px;
    color: white;
    bottom: 100%;
    right: 0;
    z-index: 2;
    line-height: 0.8;
  }
}

.custom-agent {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  .cont {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    .text-holder {
      height: auto !important;
    }
    .button-holder {
      margin-top: auto;
    }
  }
}

.gallery-wrapper {
  .gallery {
    .item {
      display: flex;
      flex-direction: column;
      row-gap: rem-calc(15);
      & > .image-wrapper,
      & > a {
        display: block;
        position: relative;
        padding-top: 65%;
        overflow: hidden;
        & > img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .gallery-carousel {
    .slick {
      &-slide {
        img {
          width: 100%;
          height: auto;
        }
      }
      &-next,
      &-prev {
        position: absolute;
        z-index: 1;
        color: #fff;
        top: 50%;
        transform: translateY(-50%);
        transition: color 0.2s ease-out;
        font-size: 2.7rem;
        cursor: pointer;
        &:hover {
          color: $red;
        }
      }
      &-next {
        right: 2rem;
      }
      &-prev {
        left: 2rem;
      }
      &-disabled {
        opacity: 0.4;
        pointer-events: none;
      }
      @media screen and (max-width: 42em) {
        &-next,
        &-prev {
          font-size: 2rem;
        }
        &-next {
          right: 0.5rem;
        }
        &-prev {
          left: 0.5rem;
        }
      }
    }
  }
}

.map {
  &-multiple {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    background: #f2f2f2;
    border: solid 1px #ced2d5;
    height: 750px;
    &-container {
      width: 60%;
      .inner {
        position: relative;
        width: 100%;
        height: 100%;
        background: gainsboro;
        & > * {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    &-aside {
      width: 40%;
      padding: rem-calc(30);
      display: flex;
      flex-direction: column;
      row-gap: rem-calc(20);
    }
    &-form {
      position: relative;
      width: 100%;
      height: 50px;
      display: flex;
      flex-shrink: 0;
      .input {
        border-radius: 0;
        border: solid 1px $black;
        border-right: 0;
        padding: 10px 16px;
        width: calc(100% - 130px);
        font-size: rem-calc(16);
        line-height: calc(24 / 16);
        outline: none;
        &::placeholder {
          color: $black;
        }
      }
      .button {
        background: $red;
        color: white;
        width: 130px;
        border: 0;
        &:hover,
        &:focus {
          background-color: $black;
        }
      }
    }
    &-list {
      height: auto;
      flex: auto;
      overflow: auto;
      display: flex;
      flex-direction: column;
      row-gap: rem-calc(20);
      .item {
        padding: rem-calc(30);
        background: white;
        border: solid 1px #ced2d5;
        cursor: pointer;
        &.active {
          border: solid 2px $red;
        }
        h3 {
          font-size: rem-calc(30);
          font-family: $font-title;
          line-height: normal;
          margin-bottom: rem-calc(8);
        }
        p {
          font-weight: 500;
          margin-bottom: rem-calc(16);
          line-height: calc(24 / 16);
        }
        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          column-gap: rem-calc(8);
          color: #000;
          font-weight: 400;
          svg {
            width: 22px;
            height: 24px;
          }
          &:hover {
            color: $red;
          }
        }
      }
    }
    @media screen and (max-width: 64em) {
      height: auto;
      flex-direction: column;
      &-container {
        width: 100%;
        .inner {
          padding-bottom: 80%;
        }
      }
      &-aside {
        width: 100%;
        max-height: 500px;
        row-gap: rem-calc(15);
        padding: rem-calc(20);
      }
      &-list {
        row-gap: rem-calc(10);
        .item {
          padding: rem-calc(18);
          p {
            margin-bottom: rem-calc(10);
          }
        }
      }
    }
  }
  &-single {
    &-content {
      display: flex;
      flex-direction: column;
      max-width: 310px;
      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: rem-calc(16);
        margin-bottom: rem-calc(16);
        img {
          flex-shrink: 0;
          max-width: 25%;
        }
        h3 {
          font-family: $font-title;
          font-size: rem-calc(30);
          line-height: normal;
        }
      }
      p {
        font-weight: 500;
        line-height: calc(24 / 16);
        margin-bottom: rem-calc(16);
      }
      .links {
        display: flex;
        flex-direction: column;
        row-gap: rem-calc(8);
        margin-bottom: rem-calc(32);
        a {
          display: flex;
          align-items: center;
          column-gap: rem-calc(8);
          color: #000;
          text-decoration: none;
          &.underline {
            text-decoration: underline;
          }
          &:hover {
            color: $red;
          }
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
      .buttons {
        display: flex;
        flex-direction: column;
        row-gap: rem-calc(16);
        .button {
          background: $red;
          color: white;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: rem-calc(8);
          height: 56px;
          &:hover {
            background-color: $black;
          }
          svg {
            width: 18px;
            height: 18px;
            margin-bottom: rem-calc(4);
          }
        }
      }
    }
    &-container {
      width: 100%;
      .inner {
        width: 100%;
        position: relative;
        padding-bottom: 65%;
        overflow: hidden;
        background: gainsboro;
        & > * {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    @media screen and (max-width: 64em) {
      &-content {
        max-width: 450px;
        margin-bottom: rem-calc(25);
      }
    }
  }
  &-style {
    .gm-style {
      &-iw-ch {
        display: none !important;
      }
      &-iw-c {
        padding: 0 !important;
        border-radius: 0 !important;
        max-width: 180px !important;
      }
      &-iw-d {
        overflow: auto !important;
        padding: 0 !important;
      }
    }
    .gm-ui-hover-effect {
      width: 24px !important;
      height: 24px !important;
      opacity: 1 !important;
      position: absolute !important;
      right: 0 !important;
      top: 0 !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      & > span {
        width: 20px !important;
        height: 20px !important;
        margin: 0 !important;
        background-color: #c0c0c0 !important;
      }
    }
    .map-popup {
      padding: rem-calc(16);
      display: flex;
      flex-direction: column;
      row-gap: rem-calc(8);
      h2 {
        font-size: rem-calc(16);
        font-weight: 700;
      }
      span {
        display: block;
        font-size: rem-calc(14);
      }
      a {
        font-size: rem-calc(14);
        font-weight: 700;
      }
    }
  }
}

.recent-properties {
  &.table {
    @media screen and (min-width: 64em) {
      .row.list {
        display: flex;
        flex-direction: column;
        row-gap: rem-calc(20);
        &::before &::after {
          display: none;
        }
        .shortlistbox {
          width: 100% !important;
          .listing {
            display: flex;
            margin: 0;
            a.link {
              display: flex;
              align-items: stretch;
              width: 100%;
              @media screen and (max-width: 64em) {
                align-items: flex-start;
              }
            }
            .image {
              position: relative;
              width: 17%;
              min-width: 200px;
              height: auto;
              flex-shrink: 0;
              padding: 0;
              @media screen and (max-width: 64em) {
                width: 150px;
                min-width: unset;
              }
              img {
                position: static;
                object-fit: contain;
                width: 100%;
                height: auto;
              }
            }
            .sub {
              width: 83%;
              border: 0;
              display: flex;
              padding-right: 330px;
              @media screen and (max-width: 64em) {
                padding-right: 165px;
              }
              @media screen and (max-width: 42em) {
                flex-direction: column;
                padding-right: 120px;
                & > * {
                  width: 100% !important;
                }
              }
            }
            .save,
            .shortlisted {
              top: 50%;
              transform: translateY(-50%);
              @media screen and (max-width: 42em) {
                top: rem-calc(10);
                transform: unset;
              }
            }
            .property-info {
              display: flex;
              height: auto;
              flex-direction: column;
              justify-content: center;
              width: 60%;
              flex-shrink: 0;
              @media screen and (max-width: 64em) {
                padding: rem-calc(10);
              }
              .price {
                order: -1;
                margin: 0;
              }
              .size {
                @media screen and (min-width: 64em) {
                  position: absolute;
                  top: 50%;
                  right: 180px;
                  transform: translateY(-50%);
                  width: 18%;
                  text-align: right;
                }
              }
            }
            .actions {
              top: 50%;
              right: 60px;
              bottom: unset;
              transform: translateY(-50%);
              height: auto;
              @media screen and (max-width: 42em) {
                top: 78%;
                right: rem-calc(16);
              }
            }
            .details {
              display: flex;
              align-items: center;
              width: 40%;
              flex-shrink: 0;
              border-top: 0;
            }
          }
        }
      }
    }
  }
}

.dark-theme {
  .sec-title,
  .map-single-content,
  .map-single-content .title h3,
  .map-single-content .links a,
  .form-wrapper,
  .homebit h3,
  .homebit p,
  .subtitle,
  .text-content,
  .text-content a:not(.button),
  .gallery-wrapper .gallery .item,
  &.home-blog .blog-listing h3 a,
  &.home-blog .blog-listing h3 a:hover,
  &.home-blog .hb-content,
  &.home-blog .hb-content .cat a,
  &.home-blog .hb-content .cat a:hover,
  &.home-blog .hb-content h2 a,
  .propertiesContainer .text-center {
    color: $white;
  }
  .form-wrapper .userform .userformsstep > .field .form-control,
  .form-wrapper .userform .userformsstep > .field input.js-autocomplete-address,
  .form-wrapper .userform .userformsstep > .field .select-dropdown {
    color: $black;
  }
  .listing a.link {
    background-color: $white;
  }
}

.slick-container {
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}

.row {
  &.prevent-flex {
    display: block !important;
    .pagination {
      clear: both;
    }
  }
  &#agent-pagination {
    & > div:not([class]),
    & > .pagination {
      width: 100%;
    }
    & > div:not([class]) {
      display: flex;
      flex-wrap: wrap;
    }
    .agent-mini {
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
      .cont {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        .text-holder {
          flex: 1 0 auto;
          height: auto;
        }
      }
    }
  }
}

.desktop-video {
  display: block !important;
  @media screen and (max-width: 64em) {
    display: none !important;
  }
}

.mobile-video {
  display: none !important;
  @media screen and (max-width: 64em) {
    display: block !important;
  }
}
