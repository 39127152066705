/** Exports to ../css/style.css **/
@import 'generic/_reset';

$black: #000; //#161e20;
$red: #cc2026;
$light-grey: #DDDEE0;
$border-size: 2px;

/** Foundation **/
@import 'foundation/_foundation';
@import 'foundation/_global';
@include foundation-everything;

.column:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
    float: left;
}

html {
	scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
}

body {
    background-color: #ffffff;
	//border: 1px solid red;
    font-family: $font-text;
}

a {
	color: $red;
}

.main-area {
    background-color: #FFF;
		max-width: 2100px;
		margin: 0 auto;

		@media screen and (min-width: 2100px) {
			overflow: hidden;
			box-shadow: 0px 0px 30px rgba(0,0,0,0.25);
		}
}

@media screen and (min-width: 800px) {
  .mainstuff {
    padding-right: 2rem;
  }
}

svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: currentColor;
}

::selection {
	background: $red;
	color: #FFF;
}

/** Elements **/
@import 'elements/header/_header';
@import 'elements/header/_mainnav';
@import 'elements/_footer';
@import 'elements/_listings';
@import 'elements/_homebanner';
@import 'elements/_mobilemenu';
@import 'elements/_homebits';
@import 'elements/_sidebarnav';
@import 'elements/_promo';

@import 'elements/_basic';
@import 'elements/_property';
@import 'elements/_offices';
@import 'elements/_sitemap';

@import 'elements/_breadcrumbs';
@import 'elements/_content';
@import 'elements/_form';
@import 'elements/_search';
@import 'elements/_pagination';
@import 'elements/_ss-search';

@import 'elements/_mapresults';
@import 'elements/_blog';
@import 'elements/_share';

@import 'elements/map/_pins';

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.desktop-clear {
  clear: both;
}

.visually-hide {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px; width: 1px;
	margin: -1px; padding: 0; border: 0;
}

@import 'plugins/_magnificpopup';
@import 'plugins/_wallop';
@import 'plugins/_slick';
@import 'plugins/_pushy';

/* Show Things */
.iseeyou,
.iseeyou {
	opacity: 0;
}

.iseeyou.active,
.iseeyou.active {
	animation: showThing 0.5s ease 1;
	opacity: 1;
}

@keyframes showThing {
	0% {
		opacity: 0;
		// transform: translateY(-100px);
	}
	100% {
		opacity: 1;
		// transform: translateY(0px);
	}
}

.enquiry-modal{
 
	.custom-form input[type="submit"], .custom-form input[type="button"], .custom-form button {
		margin-top: -10px !important;
		
	}
	 .modal-window h1 {
		text-align-last: center !important;
		font-size: 170%;

		@media screen and (max-width: 500px){
			font-size: 130% !important;
		 }  
	}

	.custom-form .field {
		@media screen and (max-width: 1024px){
			width: 80%;
			float: none;
		 }   

	}

	textarea {

		@media screen and (max-width: 500px){
			max-height: 60px;
		 }   
	}

	.custom-form {

		@media screen and (max-width: 500px){
			padding: 0px;
			border: 0px;
			overflow: scroll;
			// max-width: 300px !important;
			// max-height: 560px !important;
		 }   
	}

//Styles for modal

.modal-window {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.541);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s;
	&.show {
	visibility: visible;
	opacity: 1;
	pointer-events: auto;
	}
	& > div {
		position: relative;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		padding: 2em;
		background: white;
		max-height: 90%;
		overflow: scroll;
		max-width: 60%;

		@media screen and (max-width: 500px){
			max-width: 98% !important;
		}
	}
	header {
	  font-weight: bold;
	}
	h1 {
	  font-size: 2.5rem;
		line-height: 1.2;
	  margin: 0 0 15px;
		font-family: $font-title;
	}
  }
  
  .modal-close {
	color: rgb(32, 32, 32);
	line-height: 50px;
	font-size: 80%;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	width: 70px;
	text-decoration: none;
	&:hover {
	  color: black;
	}
  }
  
  /* Demo Styles */
  
  
  a {
	color: inherit;
  }
  
  .container {
	display: grid;
	justify-content: center;
	align-items: center;

  }
  
  .modal-window {
	& > div {
	  border-radius: 1rem;
	}
  }
  
  .modal-window div:not(:last-of-type) {
	margin-bottom: 15px;
  }
  
  small {
	color: lightgray;
  }
  
  .btn {
	background-color: white;
	padding: 1em 1.5em;
	border-radius: 1rem;
	text-decoration: none;
	i {
	  padding-right: 0.3em;
	}
  }

}

@media screen and (max-width: 1024px){
.enquiry-modal .custom-form .field {
	width: 90% !important;
}
.enquiry-modal .modal-window > div{
max-width: 90%!important;
}
.enquiry-modal .custom-form {
	padding: 0px;
	border: 0px;
	overflow: scroll;
	/* max-width: 300px !important; */
	/* max-height: 560px !important; */
}
}

@media screen and (max-width: 360px){
	.modal-window {
		& > div {
			max-width: 98% !important;
		}
		}
}

.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }

@import 'elements/_developer-custom';
	