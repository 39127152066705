/* Slider */

@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
}

.slick-track:before,
.slick-track:after {
	content: "";
	display: table;
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	display: none;
}

[dir="rtl"] .slick-slide {
	float: right;
}

.slick-slide img {
	display: block;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
	display: none;
}


/* Styling */
.gallery {
    width: 100%;
    position: relative;
}

.gallery .slide {
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		overflow: hidden;
		position: relative;
		border-left: 1px solid #FFF;
		border-right: 1px solid #FFF;
}

.gallery .slide:after {
	content: '';
	display: block;
	height: 100%;
	padding-bottom: 66.625%;
}

.gallery .apartments-gallery-controls {
    position: absolute;
    left: 0;
    bottom: -30px;
    width: 100%;
    text-align: center;
    z-index: 10;
}

.slick-slide {
	opacity: 0.65;
	transition: 0.5s all ease;
}

.slick-current.slick-active {
	z-index: 2;
	opacity: 1;
}

	@media screen and (min-width: 375px) {
		/* Controls */
	.gallery .slick-prev,
	.gallery .slick-next {
		position: absolute;
		top: 0;
		display: block;
		z-index: 9;
		cursor: pointer;
		width: 420px;
		height: 100%;
		-webkit-transition: width 0.2s ease-in-out;
		transition: width 0.2s ease-in-out;
	}
}

@media only screen and (min-width: 481px) {

	.gallery .slick-dots {
		list-style: none;
		padding: 0;
		margin: 0;
		text-align: center;
		position: absolute;
		bottom: 0;
		width: 100%;
		z-index: 9999;
		font-size: 0;
		white-space: nowrap;
		overflow: hidden;
	}

	.gallery .slick-dots li {
		margin: 0;
		padding: 0;
	}

	.gallery .slick-dots li {
		display: inline-block;
		padding: 0 5px;
		position: relative;
		top: 0;
		-webkit-transition: -webkit-transform 0.2s ease-in-out;
		transition: transform 0.2s ease-in-out;
		cursor: pointer;
	}

	.gallery .slick-dots li.slick-active {
		// -webkit-transform: translateY(-10px);
		// transform: translateY(-10px);
	}

	.gallery .slick-dots li.slick-active span:before {
		// -webkit-transform: translateY(-100%);
		// transform: translateY(-100%);
	}

	.gallery .slide {
	}

	.gallery .slide .mask {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		text-decoration: none;

	}

	.gallery .slide .mask:before,
	.gallery .slide .mask:after {
		content: '';
		background-color: #FFFFFF;
		width: 100%;
		height: 50px;
		display: block;
		position: absolute;
		left: 0;
		transform: translateY(0px);
	}

	.gallery .slide .mask:before {
		top: 0px;
		transition: all 0.5s ease;
		border-bottom: $border-size solid #FFF;
	}

	.gallery .slide .mask:after {
		bottom: 0px;
		transition: all 0.5s ease;
		border-top: $border-size solid #FFF;

	}
	/*insert CSS ti add the for sale sign*/
    .property-banner .property-gallery .image-holder .leased:after,
	.gallery .slide .mask .leased:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
		bottom: 0px;
        transition: all 0.5s ease;
		border-top: $border-size solid #FFF;
		background-image: url(../img/leased-image-overlay.png);
		-webkit-background-size: 100% 100%;
		background-size: 100%;
		background-position: 0px 0px;
		background-repeat: no-repeat;
	}
  .property-banner .property-gallery .image-holder .sold:after,
	.gallery .slide .mask .sold:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
		bottom: 0px;
		transition: all 0.5s ease;
		border-top: $border-size solid #FFF;
		background-image: url(../img/sold-image-overlay.png);
		-webkit-background-size: 100% 100%;
		background-size: 100%;
		background-position: 0px 0px;
		background-repeat: no-repeat;
	}


  .gallery .slide.slick-current .mask:before {
		transform: translateY(-50px);
	}

	.gallery .slide.slick-current .mask:after {
		transform: translateY(50px);
	}

}


	@media screen and (max-width: 3000px) {
		.gallery .slick-prev,
		.gallery .slick-next {
			width: 360px;
		}

	@media screen and (max-width: 1601px) {
		.gallery .slick-prev,
		.gallery .slick-next {
			width: 280px;
		}
	}

	@media screen and (max-width: 1000px) {
		.gallery .slick-prev,
		.gallery .slick-next {
			width: 60px;
		}
	}

	.gallery .slick-prev:hover,
	.gallery .slick-prev:focus,
	.gallery .slick-next:hover,
	.gallery .slick-next:focus {
		// width: 70px;

		&:before {
			//background-color: $red;
			text-shadow: 2px 2px 10px #000;
		}
	}

	.gallery .slick-prev:before,
	.gallery .slick-next:before {
		background-position: center;
		background-repeat: no-repeat;
		content: '';
		position: absolute;
		top: 50%;
		margin-top: -40px;
	}

	.gallery .slick-prev:after,
	.gallery .slick-next:after {
		font-size: 8px;
		color: #f69438;
		position: absolute;
		top: 50%;
		margin-top: -25px;
	}

	.gallery .slick-prev {
		left: 0;
	}

	.gallery .slick-prev:before {
		// background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUwIDUwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MCA1MDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE0LjMsMjVMMzAuMiw5LjFsNS42LDUuNUwyNS40LDI1bDEwLjQsMTAuNGwtNS42LDUuNUwxNC4zLDI1eiIvPg0KPC9nPg0KPC9zdmc+DQo=');
		right: 16px;
		z-index: 2;
		font-family: FontAwesome;
    color: #fff;
		content: '\f104';
		font-size: 340%;
	}

	.gallery .slick-prev:after {
		// content: '\E00A';
		// left: 33px;
	}

	.gallery .slick-next {
		right: 0;
	}

	.gallery .slick-next:before {
		//background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgdmlld0JveD0iMCAwIDUwMS41IDUwMS41Ij48Zz48cGF0aCBmaWxsPSIjRkZGIiBkPSJNMTk5LjMzIDQxMC42MjJsLTU1Ljc3LTU1LjUwOEwyNDcuNDI1IDI1MC43NSAxNDMuNTYgMTQ2LjM4NGw1NS43Ny01NS41MDdMMzU4LjQ0IDI1MC43NXoiLz48L2c+PC9zdmc+DQo=');
		left: 16px;
		font-family: FontAwesome;
		color: #fff;
		content: '\f105';
		font-size: 340%;
	}

	.gallery .slick-next:after {
		// content: '\E00B';
		// right: 33px;
	}

	.gallery .slick-dots span {
		display: block;
		width: 80px;
		height: 60px;
		background-size: cover;
		background-position: center center;
		background-position: no-repeat;
		box-shadow: 0 1px 10px rgba(0,0,0,0.5);
		border: $border-size solid #FFF;
		overflow: hidden;
		position: relative;
	}

	.gallery .slick-dots li.slick-active span {
		border-color: $red;
	}

}

.gallery .slide .overlay {
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FFF;
	text-decoration: none;
	transition: 0.5s all ease;
	padding: 96px 32px 32px 32px;
	padding: 6rem 2rem 2rem 2rem;

	background: linear-gradient(to bottom, rgba(#000,0) 0%, rgba(#000,0.85) 100%);

	opacity: 0;
}

.gallery .slide.slick-current .overlay {
	opacity: 1;
}

.gallery .slide .overlay .title {
	font-size: 24px;
	font-size: 1.5rem;
}

.gallery .slide.slick-current:hover .overlay .title {
	opacity: 0.6;
}

.gallery .slide .video-embed {
	position: relative;
	padding-bottom: 66.625%;
	height: 0;
	overflow: hidden;
	width: 100%;
}

.gallery .slide .video-embed iframe,
.gallery .slide .video-embed object,
.gallery .slide .video-embed embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.play-video:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -60px;
	margin-left: -40px;
	width: 0px;
	height: 0px;
	border-top: 60px solid transparent;
	border-bottom: 60px solid transparent;
	border-left: 120px solid rgba(255,255,255,0.8);
	transition: 0.5s all ease;
}

.gallery .slide.play-video:after {
  background: rgba(0, 0, 0, 0.25);
	cursor: pointer;
	transition: 0.5s all ease;
}

.play-video:hover:before {
	border-left-color: rgba(255,255,255,1);
}

.gallery .slide.play-video:hover:after {
	    background: rgba(0, 0, 0, 0.375);
}

@media screen and (max-width: 374px){
	.gallery .slick-prev:before{
		right: 20rem;
	}

	.gallery .slick-next:before{
		left: 20rem;
	}
}
