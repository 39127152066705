/* Message for no results */
.no-results {
  padding: 16px 24px;
  padding: 1rem 1.5rem;
  margin: 12px 0px;
  margin: 0.75rem 0rem;
  background: #f3f7f7;
  border: 1px solid #ced6d8;
  color: #202f39;
  border-radius: 5px;
  float: left;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
}

.listings {
  background-color: #fff;
  padding: 3em 0;
}

.sec-title {
  line-height: 1.2;
  font-size: 24px;
  margin: 0 0 15px 0;
  font-family: $font-title;
}

.sec-title a {
  float: right;
  text-decoration: none;
  font-size: 16px;
  font-size: 1rem;
  font-weight: normal;
  display: block;
  padding: 15px 15px 13px;
  font-weight: bold;
  text-transform: unset;
  border-color: $white;
  background-color: $red;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  color: #fff;
}

.sec-title a:hover,
.sec-title a:focus {
  background-color: $black;
}

.sec-title.agent-listing-title h3,
.sec-title.agent-listing-title ul,
.sec-title.agent-listing-title ul li {
  display: inline-block;
  vertical-align: top;
}
.sec-title.agent-listing-title a {
  margin: 0.25rem 0 0.25rem 0.75rem;
  border: 2px solid $red;
}
.sec-title.agent-listing-title ul li a:not(.active) {
  background: #fff;
  color: $red;
}

@media screen and (max-width: 670px) {
  .sec-title.agent-listing-title a {
    float: none;
    display: inline-block;
  }
}

.listing {
  display: block;
  text-decoration: none;
  margin: 15px 0;
  position: relative;
  transition: all 0.3s ease;
}

.listing a.link {
  display: block;
  text-decoration: none;
  background-color: #fff;
}

.listing .actions {
  position: absolute;
  bottom: 0;
  right: 0;
}

.listing .actions a {
  float: left;
  display: block;
  height: 48px;
  width: 48px;
  padding: 10px;
  border-left: 1px solid #ced2d5;
  position: relative;
}

.listing .actions a svg {
  display: block;
  width: 18px;
  height: 18px;
  fill: $black;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -9px;
}

.listing .actions a:hover {
  background-color: $red;
}

.listing .actions a:hover svg {
  fill: #fff;
}

.save,
.shortlisted {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  z-index: 2;
}

.save svg.pre,
.shortlisted svg.pre,
.save svg.post,
.shortlisted svg.post {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  height: 40px;
  transition: 0.5s all ease;
}

.save svg.pre {
  fill: rgba(0, 0, 0, 0.75);
  opacity: 1;
  visibility: visible;
}

.shortlisted svg.pre {
  opacity: 0;
  visibility: hidden;
}

.save svg.post {
  opacity: 0;
  visibility: hidden;
}

.shortlisted svg.post {
  fill: rgba($red, 0.85);
  opacity: 1;
  visibility: visible;
}

.save:focus,
.shortlisted:focus {
  outline: none;
}

/* */
.listing:hover,
.listing:focus {
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.25);
}

.listing .image {
  position: relative;
  padding-bottom: 66.625%;
  //background-color: $red;
  overflow: hidden;
}

.loader {
  position: absolute;
  width: 60px;
  height: 60px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 60px;
  text-align: center;
}

.loader .icon {
  fill: $red;
  width: 60px;
  height: 60px;
  animation: bounce 1.5s infinite;
}

.listing .loader .icon {
  background-color: darken($red, 16%);
}

.loader .shadow {
  width: 40px;
  height: 5px;
  border-radius: 50%;
  background-color: #ddd;
  margin: 0 auto;
  animation: bounceshadow 1.5s infinite;
}

.listing .loader .shadow {
  background-color: darken($red, 8%);
}

@keyframes bounceshadow {
  0%,
  20%,
  50%,
  80%,
  100% {
    width: 40px;
  }
  40% {
    width: 20px;
  }
  60% {
    width: 30px;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-50px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.listing .image img {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listing .image .sold {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0px 0px;
  //border: $border-size solid $red;
  font-weight: bold;
  width: 100%;
  height: 100%;
}
.listing .image .sold:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("../img/sold-image-overlay.png");
  background-size: 100%;
  background-position: 0px 0px;
  background-repeat: no-repeat;
}

.listing .image .leased:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("../img/leased-image-overlay.png");
  background-size: 100%;
  background-position: 0px 0px;
  background-repeat: no-repeat;
}

.listing .suburb {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 6px 10px;
  background-color: $red;
  color: #fff;
  z-index: 2;
  font-weight: bold;
}

.listing .sub {
  border: 1px solid #ced2d5;
  border-top: none;
}

.listing .property-info {
  padding: 20px;
  font-size: 16px;
  font-size: 1rem;
  color: $black;
  height: 180px;
}

.listing .property-info .title {
  color: #000;
  font-size: 16px;
  font-size: 1rem;
}

.listing .property-info .price,
.listing .property-info .when {
  font-size: 16px;
  font-size: 1rem;
  color: $black;
  text-transform: uppercase;
  margin: 5px 0 0 0;
  line-height: 1.25;
}

.listing .property-info span {
  font-weight: bold;
}

.listing .property-info span.size {
  /* color: $red; */
}

.listing .sub .details {
  font-size: 16px;
  font-size: 1rem;
  color: #162a48;
  padding: 10px;
  padding: 0.675rem;
  border-top: 1px solid #ced2d5;
  min-height: 48px;
  min-height: 3rem;
}

.listing .sub .details span {
  display: inline-block;
  line-height: 24px;
  margin: 0 10px 0 0;
  position: relative;
  padding-left: 22px;
  color: $black;
  font-weight: bold;
}

.listing .sub .details svg {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -9px;
  width: 18px;
  height: 18px;
  fill: $black;
}
