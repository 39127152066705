$main-colour: $red;

* {
  box-sizing: border-box;
  font-family: sans-serif;
}

/* Enquiry Form */
.custom-form {
    margin: 16px 0px;
    margin: 1rem 0rem;
    padding: 20px 32px;
    padding: 1.25rem 2rem;
    background: #FFFFFF;
    border-radius: 2px;
	border: 1px solid #ddd;
    color: #222222;
}

/* Overrides Foundation */
fieldset {
  border: none;
  padding: 0;
  margin: 0;
  width: auto;
}

/* Messages (eg. Validation Messages) */
.custom-form .message {
  padding: 8px 12px;
  padding: 0.5rem 0.75rem;
  margin: 12px 0px;
  margin: 0.75rem 0rem;
  background: #E3EFF2;
  border: 1px solid #ABC3CA;
  color: rgb(4, 70, 111);
  border-radius: 5px;
  float: left;
  width: 100%;

  &.good,
  &.success {
    background: rgb(225, 245, 225);
    color: darkgreen;
    border-color: rgb(156, 210, 156);
  }

  &.bad,
  &.error,
  &.required,
  &.validation {
    background: #FAE4E8;
    color: rgb(126, 7, 20);
    border-color: #D29CA6;
  }
  
  &.error {
    display: none !important;
  }
}

.custom-form {
    /* Middle Column - Basic Styles */
    .middleColumn {
        position: relative;

        /* Validation Errors */
      .message.required {
            position: absolute;
            top: 100%;
            left: 0px;
            opacity: 0.925;
            width: auto;

            &:after,
            &:before {
                bottom: 100%;
                left: 20px;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }
            &:after {
                border-color: rgba(250, 228, 232, 0);
                border-bottom-color: #FAE4E8;
                border-width: 10px;
                margin-left: -10px;
            }
            &:before {
                border-color: rgba(210, 156, 166, 0);
                border-bottom-color: #D29CA6;
                border-width: 11px;
                margin-left: -11px;
            }
        }
    }

    /* Field Labels */
    label {
        color: #222222;
        display: block;
        padding: 0px 0px 12px 0px;
        font-size: 16px;
        font-size: 1.25rem;
        line-height: 1.2;
        font-weight: 600;
    }

    .requiredField {
        & > label:after {
            content: ' *';
            color: rgb(48, 126, 192);
        }
    }

    /* Fields */
    .field {
        float: left;
        width: 100%;
        margin: 12px 0px;
        margin: 0.75rem 0rem;

        &.half {
            width: 48.5%;
            margin-right: 3%;

            &.last {
                margin-right: 0;
            }
        }

        &.third {
            width: 31.333333%;
            margin-right: 3%;

            &.last {
                margin-right: 0;
            }
        }
    }

    /* Checkboxes and Radios */
  ul {
    margin: 0;
    padding: 0;
  }

    ul.optionset li, .optionset .middleColumn > div {
    list-style: none;
        padding: 8px 0px;
        padding: 0.5rem 0rem;
    }

    ul label,
    .contact-options label,
    .field.checkbox label,
    .optionset .middleColumn > div > label {
        float: none;
        text-align: left;
        color: #555555;
        padding: 0 0 0 30px;
    }

    .radio-field label,
    .checkbox-field label {
        margin: 0;
        padding: 0;
        color: #6d717b;
        font-weight: normal;
        position: relative;
    }

    .radio-field label:hover,
    .checkbox-field label:hover {
        color: #343b41;
    }

    input[type="radio"],
    input[type="checkbox"] {
      margin: 6px 0 2px 0;
      padding: 0;
      float: left;
    }

    /* Inputs */
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="file"],
    textarea {
        width: 100%;
        font-family: $font-text;
        font-size: 16px;
        font-size: 1rem;
        line-height: 20px;
        line-height: 1.25rem;
        border: 1px solid #9FAFBA;
        border-radius: 4px;
        background-clip: padding-box;
        padding: 12px;
        margin: 0;
        transition: border 0.5s;
        -moz-appearance: none;
        -webkit-appearance: none;

        &:disabled {
            background-color: #E5EBEF;
            opacity: 0.5;
        }

        &.required {
            border-color: #D34151;
            box-shadow: 0 0 2px rgba(229, 128, 139, 0.5);
        }

        &.valid {
            border-color: #53C74B;
            box-shadow: 0 0 2px rgba(128, 242, 115, 0.5);
            color: darkgreen;
        }

        &.valid:focus,
        &.required:focus,
        &:focus {
            outline: none;
            border: 1px solid #23b7fb;
            box-shadow: 0 0 3px rgb(98, 206, 255);
        }

        /* Placeholder */
        &::-webkit-input-placeholder { color: #BECAD2; }
        &:-moz-placeholder { color: #BECAD2; }
        &::-moz-placeholder { color: #BECAD2; }
        &:-ms-input-placeholder { color: #BECAD2; }
    }

    textarea {
        overflow: auto;
        resize: vertical;
    }

    /* Select fields */
    select {
        font-family: $font-text;
        color: #222222;
        height: auto;
        border-radius: 3px;
        font-size: 16px;
        font-size: 1rem;
        line-height: 20px;
        line-height: 1.25rem;
        outline: none;
        width: 100%;
        padding: 12px;
        padding: 0.75rem;
        margin: 0;
        position: relative;
        border: 1px solid #9FAFBA;
        box-shadow: 0px 1px 3px rgba(199, 201, 218, 0.15);
        background: rgb(240,242,244);
        background: linear-gradient(to bottom, rgba(240,242,244,1) 0%,rgba(231,235,238,1) 100%);
        appearance: none;
        background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z' fill='%23222222'/></svg>");
        background-repeat: no-repeat;
        background-position: center right 14px;
        background-size: 17px;
        &:hover {
            background: rgb(241,243,245);
            background: linear-gradient(to bottom, rgba(241,243,245,1) 0%,rgba(233,236,239,1) 100%);
            background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z' fill='%23222222'/></svg>");
            background-repeat: no-repeat;
            background-position: center right 14px;
            background-size: 17px;
        }

        &.valid:focus,
        &.required:focus,
        &:focus {
            outline: none;
            border: 1px solid #23b7fb;
            box-shadow: 0 0 3px rgba(98, 206, 255, 0.5);
        }
    }

    /* End of form */
    .Actions {
        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }

    /* Submit Buttons */
    input[type="submit"],
    input[type="button"],
    button {
      display: inline-block;

      font-size: 20px;
      font-size: 1rem;
      line-height: 1;
      font-weight: bold;
      cursor: pointer;
      margin: 12px 0px;
      margin: 0.75rem 0rem;
      padding: 20px 24px;
      padding: 15px 15px 13px;

      border: solid 2px $white;
      outline: none;
      color: #FFFFFF;
      min-width: 180px;
      text-align: center;
      background-color: $main-colour;
      border-radius: 0;
      box-shadow: 0px 0px 20px rgba(0,0,0, 0.25);
      &:hover {
          background-color: $black;
      }
    }

    /* Reset Buttons */
    input[type="reset"] {
        font-size: 16px;
        font-size: 1rem;
        line-height: 20px;
        line-height: 1.25rem;
        font-weight: bold;
        cursor: pointer;
        margin: 12px 0px;
        margin: 0.75rem 0rem;
        padding: 20px 24px;
        padding: 1.25rem 1.5rem;

        border: none;
        outline: none;
        color: #222222;
        float: none;
        text-align: center;
        background-color: #EFEFEF;
        border-radius: 3px;

        &:hover {
            opacity: 0.75;
        }
    }

    /* Content in forms */
    p {
        color: #4A4B4D;
        font-size: 1em;
        line-height: 1.65em;
        margin: 12px 0px;
        margin: 0.75rem 0rem;
    }

    h1, h2, h3, h4, h5, h6 {
        color: #222222;
        font-size: 1.25em;
        font-weight: bold;
        font-family: $font-title;

        line-height: 1.2;
        font-weight: 700;

        margin: 12px 0px;
        margin: 0.75rem 0rem;
    }

    h1, h2 {
      border-bottom: 1px solid #EDEDED;
      padding-bottom: 12px;
      padding-bottom: 0.75rem;
      font-size: 1.5em;
    }

    p#ForgotPassword {
        padding: 16px 0px 0px 0px;
    }

    a {
        color: #128eb7;
        text-decoration: none;
        position: relative;
    }

    a:hover {
        text-decoration: underline;
    }
}

/* Table */
form table {
  margin: 0px;
  width: 100%;
  background-color: #f7f9fa;
  border: 1px solid #dee0e3;

  .field {
    margin: 0px;
    padding: 0px;
  }

  th {
    font-weight: bold;
    background-color: #2C3134;
    color: #FFFFFF;
    padding: 0.5rem 0.625rem;
  }

  td {
    font-size: 1rem;
  }

  a {
    color: #222222;
    font-weight: bold;
  }

  .total {
    border-top: 1px solid #dee0e3;
    td {
      font-weight: bold;
      font-size: 1.25em;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}

/* Multi Step Progress */
.userform-progress {
  .current-step-number,
  .total-step-number {
    font-weight: bold;
  }

	.progress {
		position: relative;
		height: 0.5em !important;
		background: #eee;
    border-radius: 0.25em;
	}

	.progress-bar {
		position: absolute;
		height: 0.5em !important;
		background: $main-colour !important;
    border-radius: 0.25em;
	}

	.step-buttons {
    margin: 0.75rem 0;
		margin-left: 0;
		position: relative;
    font-size: 0;

    @supports (display: flex) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }

    li {
      position: relative;
    }

    li + li:before {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -1px;
      right: 25px;
      width: 70px;
      height: 2px;
      background-color: #eee;
    }

    li + li.current:before {
      background-color: rgba($main-colour,0.5);
    }

    button {
      width: 50px;
      height: 50px;
      min-width: 0px;
      line-height: 50px;
      padding: 0;
      margin: 10px;
      margin: 0.75rem;
      border-radius: 25px;
      position: relative;
      z-index: 2;
      opacity: 1;

      left: auto !important;
      margin-left: 0 !important;
    }
	}

	.step-button-wrapper {
		display: inline-block;
		list-style-type: none;

		&.viewed .step-button-jump {
      background: $main-colour;
      color: #FFF;
		}
	}

	.step-button-jump {
    background: #eee;
    color: $main-colour;
	}
}

.step-navigation {
	.step-buttons {
		margin-left: 0;
	}

	.step-button-wrapper {
		display: inline-block;
		list-style-type: none;
	}
}

@media all and (max-width: 40.063em) {

    form .field.half,
    form .field.third {
    width: 100%;
    margin-right: 0;
    }

}


.userform .field label.right {
  color: #555;
  font-weight: 400;
  font-size: 1rem;
}
