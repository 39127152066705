.property-banner {
	position: relative;
	background-color: $black;
}


@media only screen and (min-width: 481px){
.property-banner .overlay {
	position: absolute;
	bottom: -4px;
	}
}


.property-banner .overlay {
	position: absolute;
	bottom: 5rem;
	right: 10vw;
	color: #FFF;
	z-index: 5;
}

.property-banner h1 {
	font-size: 40px;
	font-size: 2.5rem;
	font-family: $font-title;
	line-height: 1.2;
	font-weight: bold;
	margin: 0 0 8px 0;
}

.property-banner p {
	font-size: 24px;
	font-size: 1.5rem;
	line-height: 1.2;
}

.property-banner button {
	float: right;
	width: 52px;
	// height: 52px;
	position: relative;
	color: #FFF;
	margin-left: 10px;
}

.property-banner button:focus {
	outline: none;
}

.property-banner button span {
	display: block;
	font-size: 10px;
	text-align: center;
	margin-top: 56px;
	line-height: 1.4;
	text-transform: unset;
	opacity: 0.5;
	transition: 0.5s all ease;
}

.property-banner button:hover span,
.property-banner button:focus span {
	opacity: 0.8;
}

.shortlister {
    //padding-bottom: 2rem;
}

.property-banner button.save svg.pre,
.property-banner button.save svg.post,
.property-banner button.shortlisted svg.pre,
.property-banner button.shortlisted svg.post {
	width: 52px;
	height: 52px;
}

@media screen and (max-width: 64em) {
	.property-banner .overlay {
		bottom: 1rem;
		right: 5vw;
	}

	.property-banner h1 {
		font-size: 24px;
		font-size: 1.5rem;
	}

	.property-banner p {
		font-size: 16px;
		font-size: 1rem;
	}

	.property-banner button svg {
		height: 32px;
		width: 32px;
	}

	.property-banner button span {
		font-size: 9px;
		margin-top: 4px;
		line-height: 1.3;
	}
}


/* Property Details */
.property-top {
	padding-bottom: 20px;
}

@media screen and (max-width: 64em) {
	.property-top {
		padding-bottom: 15px;
	}
}

.property-top .property-info {
	// float: left;
	line-height: 24px;
	font-size: 16px;
	font-size: 1rem;
}

.property-top .property-info .title {
	font-size: 16px;
	font-size: 1rem;
	font-weight: bold;

	span {
		font-weight: bold;
	}
	span.size {
		color: $red;
	}
}

.property-top .details {
	// float: right;
	font-size: 18px !important;
	color: #162a48;
	margin-top: 10px;
}

.property-top .details span {
	display: inline-block;
	line-height: 32px;
	margin: 0 0 0 15px;
	position: relative;
	padding-left: 36px;
	color: $black;
	font-weight: bold;
}

@media screen and (max-width: 40em) {
	.property-top .details {
		text-align: left;
	}
	.property-top .details span {
		margin: 0 15px 0 0;
	}
}

.property-top .details svg {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -12px;
	width: 24px;
	height: 24px;
	fill: $black;
}

/* Content */
.property-content {
	padding: 30px 0 90px;

}

@media screen and (max-width: 64em) {
	.property-content {
		padding: 20px 0 50px;
	}
}

.description .desc-content {
	position: relative;
	max-height: 200px;
	transition: 0.5s all ease;
	overflow: hidden;
}

.description .desc-content.show {
	max-height: 800px;
}

.description .desc-content:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	height: 50px;
	width: 100%;
	background: linear-gradient(to bottom, rgba(#FFF,0) 0%, rgba(#FFF,1));
	transition: 0.5s all ease;
}

.description .desc-content.show:after {
	height: 0px;
}

.description .toggler {
	font-weight: bold;
	color: $red;
	font-size: 16px;
	font-size: 1rem;
	margin: 10px 0;
	outline: none;
}

.description .toggler:hover,
.description .toggler:focus {
	opacity: 0.75;
}

.info-table {
	display: table;
	margin: 50px 0 0 0;
	padding: 40px 0 0 0;
	border-top: 1px solid #CCC;
	width: 100%;
	font-size: 16px;
	font-size: 1rem;
}

.info-table .ir-row {
	display: table-row;
	width: 100%;
}

.ir-it {
	display: table;
	width: 100%;
	padding: 10px 10px 10px 0;
}

.info-table .ir-row > div {
	display: table-cell;
	width: 50%;
}

.strong {
	font-weight: bold;
}

/* Sidebar */
.side {
	margin: 10px 0;
}

.side-title {
	font-size: 16px;
	font-size: 1rem;
	font-weight: bold;
	margin: 0 0 5px 0;
}

.side-content {
	font-size: 16px;
	font-size: 1rem;
	position: relative;
	.add {
		display: inline-flex;
		padding: 0.5rem 0;
		align-items: center;
		width: 100%;
		svg{
			width: 40px;
			height: 40px;
			margin-right: 15px;
		}
	}
}

.calendar-btn {
	padding: 1rem;
	background: $light-grey;
	width: 100%;
	&:hover{
		background: $black;
		color: #FFFFFF;
	}
}

.side + .downloads {
  margin-top: 30px;
}

.downloads {
	margin: 20px 0;
}

a.down-button {
	background-color: $light-grey;
	color: $black;
	font-size: 16px;
	font-size: 1rem;
	display: block;
	text-align: center;
	margin: 10px 0;
	padding: 16px 10px;
	text-decoration: none;
	box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
	transition: 0.5s all ease;

	font-weight: bold;

	position: relative;

	span {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

svg#line-document {
    padding: 10px;
}

a.list-inspect {
	width: auto;
	display: inline-block;
	font-size: 16px;
	font-size: 1rem;
	font-weight: bold;
	text-decoration: none;
	background-color: $black;
	color: #FFF;
	padding: 2px 6px;
	margin: 8px 0 0 0;
	transition: 0.5s background-color ease;
}

a.list-inspect:hover {
	background-color: $red;
}

a.down-button svg {
	fill: #FFF;
	display: block;
	width: 52px;
	height: 52px;
}

a.down-button:hover {
	background-color: $black;
	color: #ffffff;
}

/* Share */
.share {
  font-size: 0;
  margin: 10px 0;
}

.share .title {
	font-size: 16px;
	font-size: 1rem;
	font-weight: bold;
	margin: 0 0 10px 0;
}

.share li {
  display: inline-block;
  margin: 0 1rem 1rem 0;
}

.share li a {
    display: block;
    padding: 0;
}

.share li a svg {
    fill: $black;
    display: block;
    width: 32px;
    height: 32px;
}

.share li a.facebook svg {
    fill: #3b5998;
}

.share li a.twitter svg {
    fill: #55acee;
}

.share li a.message svg {
    fill: #707b83;
}

.share li a.linkedin svg {
	fill: #0077B5;
}

.share li a:hover svg {
    fill: $red;
}

@media screen and (min-width: 64em) {
	.pc-move {
		padding-right: 70px;
	}
}

/* Gallery */
.property-gallery {
	position: relative;
	z-index: 2;
	border-bottom: $border-size solid #FFF;
}

.property-gallery {
	.gallery .slide .mask:before, .gallery .slide .mask:after {
		background-color: transparent;
		border: none;
	}
}


/* Less than 3 images */
.not-many-images {
	background-color: $black;
}

.not-many-images .image-holder {
	float: left;
	width: 50%;
	margin: 0 auto;
	position: relative;
}

.not-many-images .image-holder:first-child:before {
	content: '';
	position: absolute;
	top: 0;
	left: 100%;
	margin-left: -1px;
	width: 2px;
	height: 100%;
	background-color: #FFF;
	z-index: 2;
}

.not-many-images .image-holder .image {
	padding-top: 66.625%;
	background-position: center;
	background-size: cover;
}

@media screen and (max-width: 40em) {
	.not-many-images .image-holder {
		width: 100%;
		float: none;
	}
	.not-many-images .image-holder:first-child:before {
		top: 100%;
		left: 0;
		margin-top: -1px;
		margin-left: 0px;
		width: 100%;
		height: 2px;
	}
}

/* Map */
.responsive-map {
	height: 520px;
}

@media screen and (max-width: 64em) {
	.responsive-map {
		height: 320px;
	}
}

.map-marker {
	width: 60px;
	height: 85px;
	background: url('../img/marker.svg');
}

.location-title {
	font-size: 24px;
	line-height: 1.4;
}

.location-title a {
	color: $black;
	text-decoration: none;
	font-weight: bold;
}

.location-title a:hover,
.location-title a:focus {
	color: $red;
}

/* Agents */
.agents {
	padding: 80px 0;
	background-color: #f2f2f2;
	color: $black;
	// border-top: $border-size solid #FFF;
	.row{
		display: flex;
		flex-wrap: wrap;
		.columns{
			display: flex;
			flex-direction: column;
		}
	}
}

@media screen and (max-width: 64em) {
	.agents {
		padding: 50px 0;
	}
}

@media screen and (max-width: 42em) {
	.agents {
		padding: 20px 0;
	}
}

.agents .title {
	margin: 15px 0 30px 0;
	color: $black;
	font-size: 32px;
	font-size: 2rem;
	line-height: 1.4;
}

.agents .side {
	margin: 15px 0 30px 0;
	color: $black;
}

.agents .info {
	font-size: 16px;
	line-height: 1.4;
	font-size: 1rem;
	margin: 10px 0;
}

.agents .info strong {
	font-weight: bold;
}

.agents .go-button {
	display: block;
	text-decoration: none;
	background-color: $red;
	color: #FFF;
	font-size: 16px;
	font-size: 1rem;
	line-height: 36px;
	padding: 10px 18px;
	border: 2px solid #FFF;
	box-shadow: 0px 1px 3px rgba(0,0,0,0.25);
	margin: 10px 0;
	position: relative;
	font-weight: bold;
	text-align: center;
	transition: 0.5s all ease;

	span {
		position: relative;
		padding-left: 28px;
		display: inline-block;
	}

	svg {
		fill: #FFFFFF;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -10px;
		width: 20px;
		height: 20px;
	}

	&:hover,
	&:focus {
		background-color: $black;
	}
}

.agents .agent-logo {
	display: inline-block;
	background-color: $red;
	text-decoration: none;
	position: relative;
	margin: 10px 0;
	width: 72px;
  height: 72px;
  padding: 14px;

	svg {
		display: block;
		width: 44px;
		height: 44px;
		fill: #FFF;
	}

	.name {
		color: $red;
		line-height: 72px;
		font-size: 24px;
		font-size: 1.5rem;
		position: absolute;
		left: 100%;
		top: 0;
		margin-left: 20px;
		width: 16rem;
	}
}

.agents .agent {
	 margin: 15px 0;
	 background-color: #FFF;
	 color: $black;
	 position: relative;
	 transition: all 0.3s ease;
	 display: flex;
	 flex-direction: column;
	 height: 100%;
 }
.agents .agent-mini {
	margin: 15px 0;
	background-color: #FFF;
	color: $black;
	position: relative;
	transition: all 0.3s ease;
}
.agents .agent:hover {
	box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.25);
}
.agents .agent-mini:hover {
	box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.25);
}

.agents .agent .image {
	display: block;

	background-color: $black;

	background-position: center;
	background-size: cover;

	padding-top: 66.625%;
	position: relative;
    overflow: hidden;
    min-height: 350px;

	img {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 101%;
		object-fit: cover;
	    top: 50%;
	    transform: translateY(-50%);
	    max-width: 100%;
	}
}
.agents .agent-mini .image {
	display: block;

	background-color: $black;

	background-position: center;
	background-size: cover;

	padding-top: 66.625%;
	position: relative;
	overflow: hidden;
	min-height: 170px;

	img {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		top: 50%;
		transform: translateY(-50%);
		max-width: 100%;
	}
}
.agents .agent .cont {
	position: relative;
	//border: 1px solid #ced2d5;
	border-top: none;
}
.agents .agent-mini .cont {
	position: relative;
	border: 1px solid #ced2d5;
	border-top: none;
}

.agents .agent .cont .text-holder {
	padding: 30px 30px 5px 30px;
	height: 180px;
}
.agents .agent-mini .cont .text-holder {
	padding: 30px 30px 5px 30px;
	height: 132px;
}

.agents .agent .cont .button-holder {
	padding: 5px 30px 30px 30px;
	font-size: 0;
}
.agents .agent-mini .cont .button-holder {
	padding: 5px 30px 30px 30px;
	font-size: 0;
}


.agents .agent .name {
	font-size: 24px;
	font-size: 1.5rem;
	font-weight: bold;

	a {
		color: $black;
		text-decoration: none;

		&:hover {
			color: $red;
		}
	}
}
.agents .agent-mini .name {
	font-size: 24px;
	font-size: 1.5rem;
	font-weight: bold;
	font-family: $font-title;

	a {
		color: $black;
		text-decoration: none;

		&:hover {
			color: $red;
		}
	}
}

.agents .agent .phone {

}

/*.agent.iseeyou .button-holder .agent-button {
    padding: 15px 15px 13px;
    margin-top: 1rem;
}
.agent.iseeyou .button-holder .custom-button {
	padding: 15px 15px 13px;
	margin-top: 1rem;
}*/
.agent-content .button-holder .custom-button {
	padding: 15px 15px 13px;
	margin-top: 1rem;
}

.agent.iseeyou .pos {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.agent-contact {
	font-size: 20px;
	font-size: 1.15rem;
	line-height: 1;
	position: relative;
	padding-left: 40px;
    margin: 0.75rem 0 0.5rem;
}

.agent-contact a {
	color: $black;
	text-decoration: none;
	word-break: break-all;
}

.agent-contact a:hover,
.agent-contact a:focus {
	color: $red;
}

.agent-contact .icon {
	position: absolute;
	left: 0;
	top: 0;
}

.agent-contact .icon svg {
	width: 24px;
	height: 24px;
}

.button-holder .agent-button {
	font-family: "League Spartan", sans-serif;
	float: left;
	display: block;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1;
	padding: 15px 15px 13px;
	//margin: 10px 10px 0 0;
	position: relative;
	font-weight: bold;
	text-decoration: none;
	color: #fff;
	background-color: $red;
	border: 2px solid #FFF;
	box-shadow: 0px 1px 3px rgba(0,0,0,0.25);

	span {
		display: inline-block;
	}

	svg {
		fill: #fff;
		position: absolute;
		top: 50%;
		left: 18px;
		margin-top: -10px;
		width: 20px;
		height: 20px;
	}

	&:hover,
	&:focus {
		background-color: $black;
		color: $white;
		border-color: $white;
		box-shadow: none;

		svg {
			fill: $white;
		}
	}
}

.button-holder .custom-button {
	font-family: "League Spartan", sans-serif;
	float: left;
	display: block;
	font-size: 16px;
	font-size: 1rem;
	//line-height: 36px;
	padding: 10px 18px;
	//margin: 10px 10px 0 0;
	position: relative;
	font-weight: bold;
	text-decoration: none;
	color: #fff;
	background-color: $black;
	border: 2px solid #FFF;
	box-shadow: 0px 1px 3px rgba(0,0,0,0.25);

	span {
		display: inline-block;
	}

	svg {
		fill: #fff;
		position: absolute;
		top: 50%;
		left: 18px;
		margin-top: -10px;
		width: 20px;
		height: 20px;
	}

	&:hover,
	&:focus {
		background-color: #FFF;
		color: $black;
		border-color: $black;
		box-shadow: none;

		svg {
			fill: $black;
		}
	}
}

.button-holder .agent-button.withicon {
	padding: 10px 18px 10px 46px;
}
.button-holder .custom-button.withicon {
	padding: 10px 18px 10px 46px;
}

.iseeyou {
    opacity: 1!important;
}

.details p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.loadingText{
	color: white;
	text-align: center;
	min-height: 178px;
}

.loadingText span {
    background: url(../img/logo.svg);
    width: 60px;
    height: 60px;
    display: block;
    background-repeat: no-repeat;
    background-color: #cc2026;
    animation: bounce 1.5s infinite;
}

.loader .shadow {
    margin-top: 25px;
    background-color: #1f1f1f;
}

.fadein{
	opacity: 0;
    -webkit-transition: 0s;
    -moz-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
}

.show{
	opacity: 1;
    -webkit-transition:opacity 0.5s;
    -moz-transition:opacity 0.5s;
    -o-transition:opacity 0.5s;
    transition:opacity 0.5s;
}

#addeventatc1-drop.selected{
  opacity: 1;
  transform: scale(1,1) translateY(0px);
  z-index: 99999999;
  display:block;
}

#addeventatc1-drop{
  width: 230px;
  position: absolute;
  left:auto;
  top: -50px;
  right:5px;
  padding: 6px 0px 0px 0px;
  font-family: "Open Sans",Roboto,"Helvetica Neue",Helvetica,Optima,Segoe,"Segoe UI",Candara,Calibri,Arial,sans-serif;
  color: #000!important;
  font-weight: 600;
  line-height: 100%;
  background: #fff;
  font-size: 15px;
  text-decoration: none;
  text-align: left;
  margin-left: -1px;
  display: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-box-shadow: rgba(0,0,0,0.4) 0px 10px 26px;
  -moz-box-shadow: rgba(0,0,0,0.4) 0px 10px 26px;
  box-shadow: rgba(0,0,0,0.4) 0px 10px 26px;
  transform: scale(.98,.98) translateY(5px);
  z-index: 1;
  transition: transform .15s ease;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

#addeventatc1-drop a{
  color: #b53a1b;
  background-size: 22px 100%;
  display: block;
  line-height: 100%;
  background: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  color: #333;
  font-weight: 600;
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  span{
    padding: 14px 10px 14px 25px;
    display: block;
  }
  :hover{
    background:#eee;
  }
}

.success-message{
	font-size : 40px;
	color : green;
	border: 1px green;
	background-color: #CFC;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    text-align: center;
	small{
		font-size:60%;
	}
}
