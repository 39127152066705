* {
  box-sizing: border-box;
  font-family: sans-serif;
}

$paragraph-colour: $black;
$header-colour: $black;
$margin: 30px;

/* Content */
@mixin content {
  margin: 0;

  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  h1, h2, h3 {
    line-height: 1.2;
    color: $header-colour;
  }

  h4, h5, h6 {
    line-height: 1.2;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: $font-title;
    &:first-child {
      margin-top: $margin / 2;
    }
  }

  h1 {
    font-size: 2.5rem;
    margin-top: $margin;
    margin-bottom: $margin / 2;

    @media screen and (max-width: 40rem) {
      font-size: 2rem;
    }
  }

  h2 {
    font-size: 1.8rem;
    margin-top: $margin;
    margin-bottom: $margin / 2;

    @media screen and (max-width: 40rem) {
      font-size: 1.875rem;
    }
  }

  h3 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: $margin;
    margin-bottom: $margin / 2;
  }

  h4 {
    font-size: 1.375rem;
    margin-top: $margin;
    margin-bottom: $margin / 2;
  }

  h5 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: $margin;
    margin-bottom: $margin / 2;
  }

  h6 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: $margin;
    margin-bottom: $margin / 2;
  }

  p {
    color: $paragraph-colour;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.4;
    margin-top: $margin;
    margin-bottom: $margin;
  }

  img {
    margin: 1.125rem 0;
    max-width: 100%;
  }

  b,
  strong {
    font-weight: bold;
  }

  i,
  em {
    font-style: italic;
  }

  q,
  blockquote {
    font-style: italic;
  }

  a,
  a:link,
  a:visited {
    color: $header-colour;
    font-weight: 600;
    text-decoration: none;
    position: relative;
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }

  a:active,
  a:focus {
    color: #008ae0;
    text-decoration: none;
    outline: none;
  }

  ul {
    margin: 0.5rem 0;

    > li {
      list-style: disc;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $paragraph-colour;
      margin: 0.5rem 0 0.5rem 2rem;


      & > * {
        margin: 0;
      }
    }
  }

  ol {
    list-style: decimal;
    counter-reset: item;

    > li {
      counter-increment: item;
      color: $paragraph-colour;
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0.5rem 0 0.5rem 2rem;

      & > * {
        margin: 0;
      }
    }

    > li::marker {
      content: counters(item, ".") ". ";
    }
  }

  ul li p,
  ol li p {
    font-size: 1rem;
  }

  table {
    max-width: 100%;
    margin: 8px 0;
    color: $paragraph-colour;
    line-height: 1.8rem;
    border-width: 1px;
    border-color: $paragraph-colour;
    border-collapse: collapse;

    th {
      border-width: 1px;
      padding: 8px;
      border-style: solid;
      border-color: $paragraph-colour;
      text-align: left;
      font-weight: bold;
    }

    td {
      border-width: 1px;
      padding: 8px;
      border-style: solid;
      border-color: $paragraph-colour;
    }
  }

  .left, .leftAlone {
    float: left;
    clear: left;
    max-width: 99%;
    margin-right: auto;
  }

  .right, .rightAlone {
    float: right;
    clear: right;
    max-width: 99%;
    margin-left: auto;
  }

  .leftAlone, .rightAlone, .center {
    display: block;
    clear: both;
    float: none;
  }

  .center {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .left img,
  .right img {
    float: none;
    margin: 0;
  }

  img.left,
  .captionImage.left,
  img.floatleft {
    float: left;
    max-width: 96%;
    margin: 1.125rem 4% 1.125rem 0;
  }

  img.right,
  .captionImage.right,
  img.floatright {
    float: right;
    max-width: 96%;
    margin: 1.125rem 0 1.125rem 4%;
  }

  .captionImage, .embed, .media {
    position: relative;
    max-width: 100%;
    img {
      margin: 0;
      float: none;
      max-width: 100%;
      padding: 0;
    }

    p.caption {
      font-style: italic;
      color: white;
      background: $dark-gray;
      font-size: 1rem;
      padding: 0 10px 5px;
      margin: 0;
      width: 100%;
      max-width: unset;
      text-align: center;
      border-top: 1px solid $light-gray;
    }
  }

  /* Responsive iFrame Embed */
  .media, .embed {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    /* overflow: hidden; */ /* Can't hide overflow because might be caption */
    width: 100%!important; /* CMS forces inline style 600px */
    margin: 1rem 0;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.content {
  @include content;
}

.p-content {
  color: $paragraph-colour;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.6;
}

@media screen and (min-width: 800px) {
  .mainstuff {
    padding-left: 2rem;
  }
}

.userform .checkboxset legend.left, .userform .optionset legend.left {
  float: none;
  font-size: 1.25rem;
}

.text-center {
  text-align: center !important; }