footer {
    background-color: $black;
    padding: 60px 0 120px 0;
}

footer p {
    // border-bottom: 1px solid #93959d;
    color: #c5c5c5;
	margin: 10px 0;
}

footer .logo {
	display: inline-block;
	border: $border-size solid #FFF;
	width: 100px;
	margin: 20px 0 10px 0;
}

/* temporary logo - START */
footer a.logo.sixtyyearsstrong {
  border: 0.1px solid #FFF;
  width: 180px;
  padding-right: 5px;
}
/* temporary logo - END */

footer .logo:hover {
	background-color: $red;
}

/* Social */
footer .social {
  font-size: 0;
}

footer .social li {
  display: inline-block;
  margin: 0 1rem 1rem 0;
}

footer .social li a {
    display: block;
    padding: 0;
}

footer .social li a svg {
    fill: #ffffff;
    display: block;
    width: 20px;
    height: 20px;
}

footer .social li a:hover svg {
    fill: $red;
}

/* Footer nav */
footer a.ft-logo {
  display: inline-block;
}

footer a.ft-logo img {
  display: block;
  margin: 20px 0;
  margin: 1.25rem 0;
  width:206px;
}

footer h3 {
  color: #ffffff;
  margin: 20px 0 20px 0;
  font-weight: bold;
  font-size: 16px;
  font-size: 1.3rem;
  font-family: $font-title;
  line-height: 20px;
  line-height: 1.25rem;
}

footer nav li {
  margin: 20px 0;
  line-height: 18px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 20px;
  line-height: 1.25rem;
}

footer nav li a {
  color: #ffffff;
  text-decoration: none;

  &:hover,
  &:focus {
	  color: $red;
  }
}

@media screen and (min-width: 64em) {
	footer .align-right {
		text-align: right;
	}
	footer .social {
		text-align: right;
	}
	footer .social li {
		margin: 0 0 1rem 1rem;
	}
}
@media screen and (max-width: 42em) {
	footer {
		padding: 30px 0 60px 0;
	}
}
