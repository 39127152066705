$sidebar-colour: $red;

/*  Sidebar Navigation */
.sidebar-nav {
	margin: 10px 0px;
	padding: 0px;
	position: relative;
}

/* First Level */
.sidebar-nav ul {
  padding: 0;
}

.sidebar-nav ul li {
  border-bottom: 1px solid #dddddd;
  list-style: none;
  padding: 0;
}

.sidebar-nav ul li.current > a {
  font-weight: bold;
   color: $sidebar-colour;
  // background-color: #222222;
  // color: #FFFFFF;
}

.sidebar-nav ul li.section > a {
  font-weight: bold;
  color: $sidebar-colour;
  border-bottom: 1px solid $sidebar-colour;
}

.sidebar-nav ul li a {
  display: block;
  text-decoration: none;
  padding: 16px 0;
  padding: 1rem 0;
  color: #222222;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.4;
  transition: all 100ms ease 0s;
  position: relative;
  left: 0px;

  &:hover {
    color: $sidebar-colour;
  }
}

.sidebar-nav ul li a.open {
  position: relative;
}

.sidebar-nav ul li a.open:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -4px;
  height: 0;
  width: 0;
  right: 12px;
  border-top: 8px solid #FFFFFF;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

/* Second level */
.sidebar-nav ul li ul {
  padding: 6px 0px;
  background: #f8f7f7;
}

.sidebar-nav ul li ul li {
  border: none;
}

.sidebar-nav ul li ul li.current > a {
  font-weight: 600;
  background-color: transparent;
  color: $sidebar-colour;
}

.sidebar-nav ul li ul li.section > a {
  color: $sidebar-colour;
  font-weight: 600;
  background-color: transparent;
  border-bottom: none;
}

.sidebar-nav ul li ul li a {
  background: none;
  color: #555555;
  padding: 6px 12px;

  &:hover {
    background-color: transparent;
    color: $sidebar-colour;
  }
}

.sidebar-nav ul li ul li a.open:after {
  border-top: 8px solid $sidebar-colour;
}

/* Third level and beyond */
.sidebar-nav ul li ul li ul {
  padding: 0px 0px 0px 12px;
  margin: 0;
}

.sidebar-nav ul li ul li ul li {
  position: relative;
}

/* With children */
.sidebar-nav ul li.haschildren {
  position: relative;
}

.sidebar-nav ul li.haschildren > a {
  padding: 16px 40px 16px 16px;
}
