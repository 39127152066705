/* Map */
.map-listing {
	width: 310px;
	background-color: #FFF;
	position: relative;
	z-index: 99;
	cursor: pointer;
	margin-bottom: 12px;
	border: none;
	box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.35);
	font-family: $font-text;
}
.map-listing.listing .sub {
	border: none;
}
.map-listing:after {
	content: '';
	top: 100%;
	left: 50%;
	border: solid transparent;
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(#FFF, 0);
	border-top-color: #FFF;
	border-width: 10px;
	margin-left: -10px;
}
.map-listing .close-marker {
	position: absolute;
	top: -15px;
	left: -15px;
	height: 30px;
	width: 30px;
	background-color: $red;
	border-radius: 50%;
	color: #FFF;
	z-index: 3;
}
.map-listing .close-marker .text {
	color: $red;
}
.map-listing .close-marker .icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 30px;
	height: 30px;
	transform: rotate(45deg);
}
.map-listing .close-marker .icon:before {
	position: absolute;
	content: '';
	height: 16px;
	width: 2px;
	left: 50%;
	top: 50%;
	margin-top: -8px;
	margin-left: -1px;
	background-color: #FFF;
}
.map-listing .close-marker .icon:after {
	position: absolute;
	content: '';
	height: 2px;
	width: 16px;
	left: 50%;
	top: 50%;
	margin-top: -1px;
	margin-left: -8px;
	background-color: #FFF;
}
/* Marker */
.property-marker {
	position: relative;
	opacity: 1;
	transition: 1s all ease;
	width: 64px;
	height: 90px;
	background-image: url('../img/marker.svg');
	background-size: 100% auto;
	cursor: pointer;
}
.property-marker.sold {
	background-image: url('../img/marker-sold.svg');
}
.property-smallmarker {
	position: relative;
	opacity: 1;
	transition: 1s all ease;
	width: 32px;
	height: 45px;
	background-image: url('../img/marker.svg');
	background-size: 100% auto;
	cursor: pointer;
}
.property-smallmarker.sold {
	background-image: url('../img/marker-sold.svg');
}
.property-dot {
	position: relative;
	opacity: 0.85;
	transition: 1s all ease;
	width: 10px;
	height: 10px;
	background-color: rgba($red,0.85);
	border-radius: 50%;
	cursor: pointer;
	transition: 0.5s all ease;
}
.property-dot:hover {
	background-color: rgba($red,1);
}
.property-dot.sold {
	background-color: rgba(#ffcf40,0.85);
}
.property-dot.sold:hover {
	background-color: rgba(#ffcf40,1);
}
// .property-marker:before,
// .property-marker:after{
// 	display: block;
// 	content: "";
// 	position: absolute;
// 	z-index: -100;
// 	width: 50%;
// 	top: 0;
// 	bottom: 0;
// 	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
// 	transition: transform 200ms ease-out;
// }
.tooltip--tip {
	position: absolute;
	z-index: -1;
	display: block;
	width: 1rem;
	height: 1rem;
	background: #fff;
	left: 50%;
	bottom: -0.5rem;
	transform: translateX(-50%) rotate(45deg);
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
	transition: transform 200ms ease-out;
}
