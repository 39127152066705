#sitemap {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 1rem;

  > li:first-of-type {
    flex: 1 1 100%;
  }

  > li {
    flex: 1 1 19%;
    list-style: none;
    padding: 1rem 0.25rem;
    margin: 0;
    background: #eaebed;
    font-size: 1.5rem;
    text-align: center;

    ul, ol {
      text-align: left;

      li {
        margin-left: 1.5rem;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    > li {
      flex: 1 1 auto;
    }
  }

  padding-bottom: 100px;
}
