/* Search */

.searchResults .whatResults {
	padding: 32px;
	padding: 2rem;
	margin: 1rem 0;
	border-radius: 0;
	background-color: #FFFFFF;
	border: 1px solid #BEC5CA;

	h1 {
		font-size: 32px;
	  font-size: 2rem;
		line-height: 1.2;
		color: $red;
		margin: 0;
		font-family: $font-title;
	}

	h2, h3 {
		font-size: 24px;
		font-size: 1.5rem;
		line-height: 1.2;
		color: $red;
		margin: 0;
		font-family: $font-title;
	}

	p {
		font-size: 16px;
		font-size: 1rem;
		line-height: 1.4;
		margin: 0;
	}
}

.searchResults ul {
	padding: 0;

	li {
		list-style: none;
		margin: 0;
		padding: 32px 0px;
		padding: 2rem 0rem;
		border-bottom: 1px dotted #d4dde4;

		&:last-child {
			padding-bottom: 0px;
			border-bottom: none;
		}
	}
}

.searchResults ul li {
	h4 {
		padding: 0;
		margin: 8px 0px;
		margin: 0.5rem 0rem;
		color: $red;
		font-size: 24px;
		font-size: 1.5rem;
		line-height: 28px;
		line-height: 1.75rem;
		font-weight: bold;
		font-family: $font-title;

		a {
			color: $red;
			text-decoration: none;
		}
		a:hover,
		a:focus {
			text-decoration: underline;
		}
	}

	p {
		padding: 0;
		margin: 8px 0px;
		margin: 0.5rem 0rem;
		font-size: 16px;
		font-size: 1rem;
		line-height: 1.4;
	}
}

.searchResults ul li a.readMoreLink {
	margin: 8px 0px;
	margin: 0.5rem 0rem;
	display: inline-block;
	font-weight: 500;
	font-size: 16px;
	font-size: 1rem;
	font-family: $font-title;
	line-height: 1;
	text-transform: unset;

	text-decoration: none;
	transition: all 0.3s ease;
	padding: 15px 15px 13px;
	border: 2px solid $red;
	color: $red;
	font-weight: bold;

	&:hover {
		color: #FFFFFF;
		text-decoration: none;
		background-color: $red;
	}
}

@media screen and (min-width: 760px) {

	section.basic-area .row .large-12 {
	    position: relative;
	    
	    #Content.searchResults .rt-right {
		    position: absolute;
		    right: 1rem;
		    top: 1.5rem;
		    z-index: 1;
		}
	}
	.sec-title.agent-listing-title {
	    display: inline-block;
	    width: 100%;
	}	
}
@media screen and (max-width: 760px) {
	.sec-title .sec-title.agent-listing-title ul li:first-child a {
	    margin-left: 0;
	}
}