.home {
  background-color: #fff;
}

/* Home Call to actions */
.home-blog {
  margin: 100px 0;
  background-color: #ffffff;
}

@media screen and (max-width: 42em) {
  .home-blog {
    margin: 50px 0;
  }
}

.home-blog .title {
  font-size: 32px;
  font-size: 2rem;
  line-height: 32px;
  line-height: 2rem;
  margin: 0 0 32px 0;
  margin: 0 0 2rem 0;
}

.home-blog .title a {
  display: block;
  float: right;

  padding: 15px 15px 13px;
  font-weight: bold;
  text-transform: unset;
  background-color: $red;
  border: 2px solid $white;
  color: #fff;
  margin: 0 0 0 12px;
  text-decoration: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-size: 1rem;
  line-height: 1;
}

.home-blog .title a:hover,
.home-blog .title a:focus {
  background-color: $black;
  border-color: $white;
}

.home-blog .image {
  display: block;
}

.home-blog .image:hover {
  opacity: 0.75;
}

.home-blog .hb-content h2 {
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: bold;
  font-family: $font-title;
  margin: 20px 0 10px 0;
}

@media screen and (max-width: 42em) {
  .home-blog .hb-content h2 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.home-blog .hb-content h2 a {
  color: $black;
  text-decoration: none;
  font-size: 1.8rem;
  font-family: $font-title;
  line-height: 1.2;
  display: inline-block;
  &:hover,
  &:focus {
    color: $red;
  }
}

.home-blog .hb-content .date {
  text-transform: uppercase;
  margin: 10px 0 10px 0;
  color: #757575;
  font-weight: bold;
}

.home-blog .hb-content p {
  font-size: 16px;
  font-size: 1rem;
}

.home-blog .hb-content .cat {
  text-transform: uppercase;
  margin: 10px 0 10px 0;
  font-weight: bold;
}

.home-blog .hb-content .cat a {
  color: #757575;
  text-decoration: none;
}

.home-blog .hb-content .cat a:hover {
  color: $red;
}

/* Blog Feature */
.blog-feature {
  margin-bottom: 30px;
}

/* Blog Listing */
.home-blog .blog-listing {
  position: relative;
  padding-left: 207px;
  margin-bottom: 30px;
  min-height: 129px;
}

.home-blog .blog-listing .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 192px;
}

@media screen and (max-width: 42em) {
  .home-blog .blog-listing {
    min-height: 107px;
    padding-left: 175px;
  }
  .home-blog .blog-listing .image {
    width: 160px;
  }
}

@media screen and (max-width: 36em) {
  .home-blog .blog-listing {
    padding-left: 0;
  }
  .home-blog .blog-listing .image {
    width: auto;
    position: relative;
    top: auto;
    left: auto;
    margin: 0 0 10px 0;
  }
}

.home-blog .blog-listing h3 {
  font-size: 1.3rem;
  line-height: 1.2;
  font-weight: bold;
  font-family: $font-title;
}

.home-blog .blog-listing h3 a {
  display: inline-block;
  text-decoration: none;
  color: $black;
}

.home-blog .blog-listing h3 a:hover {
  color: $red;
}

.home-blog .blog-listing .cat {
  text-transform: uppercase;
  margin: 5px 0 0 0;
  color: #757575;
  font-weight: bold;
}

// .home-blog .hb-content a.go-button {
// 	position: absolute;
// 	top: 100%;
// 	left: -2px;
// 	border: 2px solid $black;
// 	padding: 20px 40px;
// 	font-size: 16px;
// 	font-size: 1rem;
// 	text-decoration: none;
// 	color: $red;
// 	font-weight: bold;
// 	text-transform: uppercase;
// }
//
// .home-blog .hb-content a.go-button:hover,
// .home-blog .hb-content a.go-button:focus {
// 	background-color: $red;
// 	color: #FFF;
// }

@media screen and (max-width: 64em) {
  .home-blog {
  }
}

/* Something */
.home-today {
  margin: 100px 0;
  text-align: center;
}

.home-today h2 {
  font-size: 32px;
  font-size: 1.8rem;
  font-family: $font-title;
  margin: 0 0 30px 0;
}

.home-table {
  display: flex;
  flex-wrap: wrap;
}

.homebit {
  float: left;
  width: 50%;
  text-align: left;
  border-bottom: 1px solid #ced2d5;

  &:nth-child(2n-1) {
    border-right: 1px solid #ced2d5;
  }

  &:last-child,
  &:nth-last-child(2) {
    border-bottom: none;
  }
}

.homebit a.go {
  display: block;
  text-decoration: none;
  position: relative;
  padding: 60px 60px 60px 210px;
}

.homebit .read {
  display: inline-block;
  padding: 2px 10px;
  font-weight: bold;
  text-transform: unset;
  background-color: $red;
  border: 2px solid $white;
  color: #fff;
  margin: 12px 0 0 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  line-height: 1;
  &:hover {
    background-color: $black;
  }
}

.homebit .icon {
  perspective: 1000px;

  position: absolute;
  top: 60px;
  left: 60px;
  width: 120px;
  height: 120px;

  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;

    position: relative;
  }

  /* flip the pane when hovered */
  &:hover .flipper {
    transform: rotateY(180deg);
  }

  .front,
  .back {
    backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 120px;
  }

  .front {
    background-color: #f2f2f2;
    border-radius: 50%;

    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);

    svg {
      fill: $black;
    }
  }

  /* back, initially hidden pane */
  .back {
    background-color: $red;
    border-radius: 50%;
    transform: rotateY(180deg);

    svg {
      fill: #fff;
    }
  }

  svg {
    display: block;
    width: 120px;
    height: 120px;
  }
}

.homebit h3 {
  font-weight: bold;
  font-size: 1.3rem;
  font-family: $font-title;
  line-height: 1.3;
  color: $black;
}

.homebit p {
  color: $black;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.6;
  margin: 5px 0 0 0;
}

.homebit a.go:hover {
}

.homebit a.go:hover .icon svg {
}

.homebit a.go:hover h3 {
}

.homebit a.go:hover p {
}

@media screen and (max-width: 60em) {
  .homebit {
    width: 100%;
    border-right: none;

    &:nth-child(2n-1) {
      border-right: none;
    }
    &:last-child {
      border-bottom: none;
    }
    &:nth-last-child(2) {
      border-bottom: 1px solid #ced2d5;
    }
  }
  .homebit a.go {
    padding: 60px 0 60px 150px;
  }
  .homebit .icon {
    left: 0px;
  }
}

@media screen and (max-width: 42em) {
  .home-today {
    margin: 50px 0;
  }
  .home-today h2 {
    margin: 0;
  }
  .homebit a.go {
    padding: 60px 0;
    text-align: center;
  }
  .homebit .icon {
    position: relative;
    top: auto;
    left: auto;
    margin: 0 auto 30px auto;
  }
}

/* Recent Properties */
.recent-properties {
  padding: 0;
  background: #fff;
  padding: 100px 0;
}

@media screen and (max-width: 64em) {
  .recent-properties {
    padding: 50px 0;
  }
}
/* Home Gallery Section */
.home-gallery {
  padding: 100px 0;
  text-align: center;
}

@media screen and (max-width: 42em) {
  .home-gallery {
    padding: 50px 0;
  }
}

.home-gallery h2 {
  font-size: 32px;
  font-size: 1.8rem;
  font-family: $font-title;
  margin: 0 0 30px 0;
}

/* Find Agent or Office */
.find-office {
  background-color: #f2f2f2;
  padding: 0;
}

.find-office .contain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.find-office .icon {
  width: 140px;
  height: 140px;
  margin: -60px 0;
  background-color: $black;
  border-radius: 50%;
  float: left;
  margin-right: 15px;
}

.find-office .icon svg {
  display: block;
  width: 120px;
  height: 120px;
  margin: 10px;
  fill: #ffffff;
}

.find-office .field {
  float: left;
  margin: 30px 0;
  padding: 0 15px;

  @supports (display: flex) {
    flex-grow: 1;
  }
}

.find-office .field.larger {
  @supports (display: flex) {
    flex-grow: 6;
  }
}

.find-office .field.largest {
  @supports (display: flex) {
    flex-grow: 20;
  }
}

.find-office label {
  display: block;
  line-height: 36px;
  padding: 10px 0;
  font-size: 24px;
  font-size: 1.5rem;
  font-family: $font-title;
  // text-align: center;
}

.find-office input[type="text"] {
  line-height: 36px;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  border: $border-size solid #fff;
  width: 100%;
  transition: 0.5s all ease;
}

.find-office input[type="text"]:focus {
  border-color: #55d0ff;
  outline: none;
  -webkit-box-shadow: 0px 0px 20px rgba(85, 208, 255, 0.35);
  box-shadow: 0px 0px 20px rgba(85, 208, 255, 0.35);
}

.find-office input[type="submit"] {
  line-height: 36px;
  padding: 12px 15px 8px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: unset;
  background-color: $red;
  border: 2px solid #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  color: #fff;
  width: 100%;
  height: 60px;
}

.find-office input[type="submit"]:hover,
.find-office input[type="submit"]:focus {
  background-color: $black;
}

@media screen and (max-width: 60em) {
  .find-office {
    padding: 10px 0;
  }
  .find-office .contain {
    @supports (display: flex) {
      flex-wrap: wrap;
    }
  }
  .find-office .field {
    margin: 10px 0;
    padding: 0;
  }
  .find-office .field.larger,
  .find-office .field.largest {
    padding-left: 155px;

    @supports (display: flex) {
      flex-basis: 100%;
    }
  }
}

@media screen and (max-width: 46em) {
  .find-office .icon {
    margin: -60px 0 0 0;
  }
  .find-office label {
    text-align: center;
  }
  .find-office .field.larger,
  .find-office .field.largest {
    padding-left: 0;
  }
}

/* Newsletter Subscription*/
.newsletter_subscription {
  background-color: $black;
  padding: 0;
  margin-bottom: 3rem;
}

.newsletter_subscription .contain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter_subscription .icon {
  width: 140px;
  height: 140px;
  margin: -60px 0;
  background-color: $black;
  border-radius: 50%;
  float: left;
  margin-right: 15px;
}

.newsletter_subscription .icon svg {
  display: block;
  width: 120px;
  height: 120px;
  margin: 10px;
  fill: #ffffff;
}

.newsletter_subscription .field {
  float: left;
  margin: 20px 0;
  padding: 0 5px;

  @supports (display: flex) {
    flex-grow: 1;
  }
}

.newsletter_subscription .field.larger {
  @supports (display: flex) {
    flex-grow: 6;
  }
}

.newsletter_subscription .field.largest {
  @supports (display: flex) {
    flex-grow: 20;
  }
}

.newsletter_subscription label {
  display: block;
  line-height: 36px;
  padding: 10px 0;
  font-size: 24px;
  font-size: 1.5rem;
  font-family: $font-title;
  color: $white;
  // text-align: center;
}

.newsletter_subscription input[type="email"] {
  line-height: 36px;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  border: $border-size solid #fff;
  width: 100%;
  transition: 0.5s all ease;
}

.newsletter_subscription input[type="email"]:focus {
  border-color: #55d0ff;
  outline: none;
  -webkit-box-shadow: 0px 0px 20px rgba(85, 208, 255, 0.35);
  box-shadow: 0px 0px 20px rgba(85, 208, 255, 0.35);
}

.newsletter_subscription input[type="submit"] {
  line-height: 36px;
  padding: 10px 18px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: unset;
  background-color: $black;
  border: 2px solid #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  color: #fff;
  width: 100%;
}

.newsletter_subscription input[type="submit"]:hover,
.newsletter_subscription input[type="submit"]:focus {
  background-color: $red;
}

label.center-align {
  text-align: center;
}

@media screen and (max-width: 60em) {
  .newsletter_subscription {
    padding: 10px 0;
  }
  .newsletter_subscription .contain {
    @supports (display: flex) {
      flex-wrap: wrap;
    }
  }
  .newsletter_subscription .field {
    margin: 10px 0;
    padding: 0;
  }
  .newsletter_subscription .field.larger,
  .newsletter_subscription .field.largest {
    padding-left: 155px;

    @supports (display: flex) {
      flex-basis: 100%;
    }
  }
}

@media screen and (max-width: 46em) {
  .newsletter_subscription .icon {
    margin: -60px 0 0 0;
  }
  .newsletter_subscription label {
    text-align: center;
  }
  .newsletter_subscription .field.larger,
  .newsletter_subscription .field.largest {
    padding-left: 0;
  }
}
