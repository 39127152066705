.promo {
	background-position: center;
	background-size: cover;
	text-align: center;
	background-color: $black;
	border-top: $border-size solid #FFF;
}

.promo .overlay {
	padding: 170px 0;
}

.promo h2 {
	color: #FFF;
	font-size: 1.8rem;
	line-height: 1.25;
	font-weight: bold;
	margin: 0 0 20px 0;
	font-family: $font-title;
}

.promo a.button {
	display: inline-block;
	padding: 15px 15px 13px;
	text-transform: unset;
	color: #FFF;
	font-size: 1rem;
	text-decoration: none;
	font-weight: bold;
	border: $border-size solid #FFF;
	margin: 0 0 10px 0;
	box-shadow: 0px 0px 20px rgba(0,0,0, 0.25);
	line-height: 1;
}

.promo a.button:hover,
.promo a.button:focus {
	border-color: $red;
	background-color: $red;
}

@media screen and (max-width: 42em) {
	.promo .overlay {
		padding: 60px 0;
	}

	.promo h2 {
		// font-size: 32px;
		font-size: 1.6rem;
	}

	.promo a.button {
		padding: 10px 20px;
		font-size: 16px;
		font-size: 1rem;
	}
}
