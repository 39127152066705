.map-area {
	padding-top: 30px;
	padding-left: 400px;
	padding-bottom: 30px;
	position: relative;
	.property-info {
		max-height: 180px;
		height: unset;
	}
}

.map-left {
	padding: 0 0.9375rem;
	margin-top: 30px;
	position: absolute;
	top: 0;
	left: 0;
	width: 400px;
	height: calc(100% - 30px);
	overflow: hidden;
	display: flex;
	flex-direction: column;
	> div#propContainer {
		height: 100%;
		width: calc(100% + 15px);
		padding-right: 5px;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			width: 10px;
			background-color: #d3d8d9;
		}

		&::-webkit-scrollbar-track {
		}

		&::-webkit-scrollbar-thumb {
			background-color: #566266;
			border-radius: 5px;
		}

		.pagination {
			margin-bottom: 0;
		}
	}
}

.map-area .responsive-map {
	height: 800px;
	height: calc(100vh - 292px);
	min-height: 620px;
}

.map-right {

}

@media screen and (max-width: 52em) {
	.map-area .responsive-map {
		height: 320px;
	}

	.map-area {
		padding-left: 0;
	}

	.map-left {
		padding-top: 0.9375rem;
		position: relative;
		top: auto;
		left: auto;
		width: 100%;
		height: auto;
		overflow: visible;
		> div#propContainer {
			overflow: visible;
			width: 100%;
			padding-right: 0;
		}
	}
}
