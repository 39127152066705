.offcanvas {
	display: none;
}

@media screen and (max-width: 820px) {
	.offcanvas {
		display: block;
		background-color: #2b2b2b;
	    list-style: none;
	    width: 400px;
	    height: 100%;
	    position: fixed;
	    top: 0;
	    z-index: 0;

	    padding: 2rem;
	    margin: 0;

	    box-sizing:border-box;
	    overflow-y: scroll;
	}

	.offcanvas.left {
	    left: -400px;
	}
	.offcanvas.left.open {
	    left: 0;
	}
	.offcanvas.right {
	    left: 100%;
	}
	.offcanvas.right.open {
	    left: 25%;
	}

	.offcanvas-site-wrap {
	    min-width: 100%;
	    min-height: 100%;
	    position: relative;
	    top: 0;
	    bottom: 100%;
	    left: 0;
	    z-index: 1;
	}

	.offcanvas-site-wrap.open.left {
	    left: 400px;
	}
	.offcanvas-site-wrap.open.right {
	    left: -75%;
	}

	.offcanvas-site-wrap, .offcanvas {
	    transition: left 0.4s;
	}
}


/* Menus */
.offcanvas nav {
	margin-bottom: 1rem;
}

/* Big Menu */
.offcanvas nav.big li a {
	font-size: 2rem;
	color: #FFF;
	text-decoration: none;
	display: block;
}

.offcanvas nav.big li a:hover {
	color: $red;
}

/* Small Menu */
.offcanvas nav.small li a {
	font-size: 1rem;
	color: #b7b7b7;
	text-decoration: none;
}

.offcanvas nav.small li a:hover {
	color: $red;
}

/* Social */
.offcanvas .social {
	font-size: 0px;
	margin-bottom: 1rem;
}

.offcanvas .social li {
	display: inline-block;
	margin: 0.5rem 1rem 0 0;
}

.offcanvas .social li a {
	display: block;
}

.offcanvas .social li a svg {
	fill: #b7b7b7;
	display: block;
	width: 24px;
	height: 24px;
}

.offcanvas .social li a:hover svg {
	fill: #d8d8d8;
}

/* Open Button */
.nav-button {
	padding: 0.5rem 1rem;
	font-size: 1.5rem;
	line-height: 1.5rem;
	color: #FFFFFF;
	background: #7f3f98;
	border-radius: 5px;
	text-decoration: none;

	background-color: $red;

	z-index: 4;
	cursor: pointer;

	display: none;
}

.nav-button .label {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	top: -100px;
	left: -100px;
}

.nav-button .icon {
	display: block;
	position: relative;
	height: 30px;
	width: 30px;
}

.nav-button .icon span {
	display: block;
	width: 100%;
	height: 3px;
	background-color: #FFFFFF;
	margin: 0.5rem 0;
}

@media screen and (max-width: 820px) {
	.nav-button {
		display: block;
	}
}

/* Sub */
.offcanvas .sub {
	margin-bottom: 1rem;
	font-size: 0.875rem;
	color: #FFF;
}
