/* Page Banner */
.page-banner {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

/* */
.basic-area {
	padding: 30px 0;
}
