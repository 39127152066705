/* Main Nav */
.primary-nav {
	float: left;
    text-align: left;
    font-size: 0;
	margin-left: 80px;

	@supports (display: flex) {
        position: relative;
		width: auto;
    }
}

.primary-nav ul {
    position: relative;
    margin: 0;

	display: inline-block;

    @supports (display: flex) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.primary-nav li {
    display: inline-block;
    font-size: 1rem;
    margin: 0;
    list-style: none;
    position: relative;

    @supports (display: flex) {
        flex-basis: 1;
    }
}

.primary-nav li a {
    color: #FFF;
    text-align: center;
    display: block;
    text-decoration: none;
		font-size: 16px;
		font-size: 1rem;
    line-height: 28px;
    white-space: nowrap;
    position: relative;
    padding: 10px 20px;
	// font-weight: bold;
}

@media screen and (max-width: 74em) {
  .primary-nav li a {
    padding: 25px 15px;
  }
}

.primary-nav li:hover > a {
	background: $red;
}

.primary-nav li a svg {
	display: block;
	width: 40px;
	height: 40px;
	margin: 0 auto 10px auto;
	fill: #FFF;
}

/* Dropdown */
.primary-nav li .dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 300px;
    background-color: #FFF;
    transition: 0.2s margin-top ease, 0.2s opacity ease;
    box-shadow: 0 1px 10px rgba(0,0,0,0.1);
    padding: 10px 0;
    text-align: left;
    margin: 0;
    z-index: 3;
}

.primary-nav li:hover .dropdown {
    display: block;
    visibility: visible;
    opacity: 1;
    transition: 0.2s opacity ease 0.2s;
}

.primary-nav li ul li {
    display: block;
    margin: 0;
}

.primary-nav li ul li:not(:last-child) {
    margin: 0 0 0.25rem;
}

.primary-nav li ul li a {
    display: block;
    text-align: left;
    font-size: 1rem;
	font-weight: normal;
    padding: 10px 20px;
    color: #000;
    white-space: normal;
	height: auto;
}

.primary-nav li ul li a:focus,
.primary-nav li ul li a:hover {
    color: #FFF;
}
/* Third Level */
.primary-nav li ul li ul {
    padding: 0;
    position: static;
}

.primary-nav li ul li ul li {
    margin: 0;
}

.primary-nav li ul li ul li a {
    font-size: 1rem;
}

/* Show/Hide */
.menu-btn {
	float: right;

	font-size: 24px;
	font-size: 1.5rem;
	font-weight: bold;
	color: #FFF;
	display: block;
	padding: 16px 0px 16px 0px;
	margin: 10px 0;
	border: $border-size solid #FFF;
	text-decoration: none;
	position: relative;
  line-height: 24px;
	margin-left: auto;

	text-indent: 100%;
  overflow: hidden;
  height: 60px;
  width: 60px;

	display: none;
}

.menu-btn .icon {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -15px;
	height: 30px;
	width: 20px;
	margin-left: -10px;

	span {
		height: 2px;
		background-color: #FFF;
		display: block;
		margin: 6px 0;
		border-radius: 1px;
	}
}

.menu-btn:hover,
.menu-btn:focus {
	color: $red;
	background-color: #FFF;
	outline: none;

	.icon span {
		background-color: $red;
	}
}

@media screen and (max-width: 64em) {
	.primary-nav {
		display: none;
	}
	.menu-btn {
		display: block;
	}
}
