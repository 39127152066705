.page-banner {
  background-color: #000;
  background-size: cover;
  background-position: center;

  color: #FFF;
  overflow: hidden;
  text-align: center;
  width: 100%;
}

.page-banner .overlay {
  background-color: rgba($black,0.5);
  padding: 250px 0 90px 0;
}

@media screen and (max-width: 64em) {
	.page-banner .overlay {
		padding: 60px 0 60px 0;
	}
}

.page-banner h1 {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: bold;
  font-family: $font-title;
  margin: 0;
  line-height: 1.2;
}

.page-banner .break {
	height: 2px;
	width: 100px;
	background-color: $red;
	background: linear-gradient(to right, $red 0%, $red 100%);
	margin: 20px auto 20px auto;
}

.page-banner .breadcrumbs {
  color: #FFF;
  text-transform: uppercase;
  letter-spacing: 0.015em;
  margin: 15px 0 0 0;
}

.page-banner .breadcrumbs span {
  opacity: 0.75;
  margin: 0 10px;
}

.page-banner .breadcrumbs a {
  color: #FFF;
  text-decoration: none;
}
